import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import InputWithLabel from "components/InputWithLabel";
import ObjectiveProgressIndicator from "components/teacher/ObjectiveProgressIndicator";
import Avatar from "components/Avatar";
import { useAuth0 } from "@auth0/auth0-react";

const StudentNotesModal = (props: any) => {
  const {
    selectedActivity,
    currentSchoolId,
    currentClassroomTeacherId,
    open,
    handleClose,
    student,
  } = props;
  const { courseId } = useParams<{ courseId?: string }>();

  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [aiFeedback, setAiFeedback] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const handleSave = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (!courseId)
        throw new Error(
          "Unable to create student note. Expected course ID and got none.",
        );
      await api.note.update(
        {
          courseId,
          studentId: student?.id,
          note,
        },
        token,
      );
    } catch (e: any) {
      props.setSnackbar({
        open: true,
        message: e.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
    handleClose();
  };

  useEffect(() => {
    setNote(student?.notes || "");
    setAiFeedback(
      (student?.analytics && student?.analytics[0].overall_summary) ||
      "There is no AI feedback available yet because the student has not yet started any activities!",
    );
  }, [student]);

  return (
    <Modal
      disabled={loading}
      open={open}
      onClose={handleClose}
      onSubmit={handleSave}
      title="Student Notes"
      submitText="Save"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          gap: 2,
        }}
      >
        <Avatar
          selectedUser={student}
          sx={{ width: 50, height: 50, fontSize: 18 }}
        />
        <Typography variant="h6">{student?.name}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontFamily: "inter", letterSpacing: 1.5 }}
            >
              NOTES
            </Typography>
          </Box>
          <InputWithLabel
            noLabel
            fullWidth
            multiline
            rows={!selectedActivity && 10}
            variant="outlined"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              overflow: "scroll",
              minWidth: 400,
            }}
            label="Notes"
            value={note}
            handleValueChange={(e: any) => setNote(e.target.value)}
          />
        </Grid>
        {selectedActivity && student?.analytics?.[0]?.objectives_summary && (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontFamily: "inter", letterSpacing: 1.5 }}
              >
                OBJECTIVE PROGRESS
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 4, pl: 2 }}>
              <ObjectiveProgressIndicator
                analysis={{
                  objectives_summary: student.analytics[0].objectives_summary,
                }}
              />
            </Box>
          </Grid>
        )}
        {selectedActivity && (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontFamily: "inter", letterSpacing: 1.5 }}
              >
                AI FEEDBACK
              </Typography>
            </Box>
            <InputWithLabel
              disabled
              noLabel
              fullWidth
              multiline
              rows={10}
              variant="outlined"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                overflow: "scroll",
              }}
              label="Notes"
              value={aiFeedback}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentNotesModal);
