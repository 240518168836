import { authInstance, createAuthHeaders } from "lib/api/utils";

const llmRolesList = (token: string) => {
  return authInstance().get<LLMRolesResponse>(
    `/llm_roles/`,
    createAuthHeaders(token),
  );
};

const llmRoles = { list: llmRolesList };

export default llmRoles;
