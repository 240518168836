import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";

const PlanSelector = (props: any) => {
  const { planId, setPlanId } = props;
  const [plans, setPlans] = useState<Array<any>>([]);

  const handleChange = (event: any) => {
    setPlanId(event.target.value);
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await api.plan.list();
        setPlans(response.data.plans);
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    };

    fetchPlans();

    return () => {
      setPlans([]);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="planId-selector-label">Plan</InputLabel>
      <Select
        labelId="planId-selector-label"
        id="planId-selector"
        value={planId}
        label="Plan"
        onChange={handleChange}
      >
        {plans &&
          plans.map((plan) => <MenuItem value={plan.id}>{plan.name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(PlanSelector);
