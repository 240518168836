import Modal from "components/Modal";
import { Button } from "@mui/material";

/**
 * A modal that asks the user if they are sure they want to delete the given item.
 * @param {function} deleteApiCall - the API call to make when the user confirms deletion
 * @param {boolean} open - whether the modal is open or not
 * @param {function} setOpen - the function to call with a boolean argument to update the open state
 * @param {string} itemTitle - the title of the item to be deleted
 * @returns {JSX.Element}
 */
const DeleteModal = ({ deleteFunction, open, onClose, itemTitle }: any) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Are you sure you want to delete '${itemTitle}'?`}
      onSubmit={() => {
        deleteFunction();
      }}
      submitText="Confirm"
      cancelText="Cancel"
    ></Modal>
  );
};

export default DeleteModal;
