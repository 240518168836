import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import theme from "theme";

// @ts-expect-error TS(2307): Cannot find module 'assets/logo.png' or its corres... Remove this comment to see the full error message
import ImageLogo from "assets/logo.png";

const logoSize = 44;

const AppLogo = ({ showMargin = true, isDesktop }: any) => {
  const history = useHistory();
  const handleButtonClick = () => {
    history.push("/");
  };

  return (
    <Box
      onClick={handleButtonClick}
      sx={{
        cursor: "pointer",
        display: "flex",
        gap: 2,
        px: 2,
        marginTop: showMargin ? theme.spacing(6) : 0,
        marginBottom: showMargin ? theme.spacing(10) : 0,
        alignItems: "center",
        "&:hover": {
          opacity: 0.5,
        },
      }}
    >
      <img src={ImageLogo} alt="logo" width={logoSize} height={logoSize} />
      {isDesktop && (
        <Typography
          component="h1"
          variant="h5"
          fontSize={32}
          sx={{
            cursor: "pointer",
          }}
          color="white"
        >
          Ellie
        </Typography>
      )}
    </Box>
  );
};
export default AppLogo;
