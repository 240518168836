import React from "react";
import { Box, Typography } from "@mui/material";

// @ts-expect-error TS(2307): Cannot find module 'assets/systemuserchaticon.svg'... Remove this comment to see the full error message
import { ReactComponent as SystemUserIcon } from "assets/systemuserchaticon.svg";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  solarizedLight,
  vs2015,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

const MessageComponent = ({
  message,
  isReceived = false,
  timeStamp = null,
}: any) => {
  // function to process inline code segments within text
  const processInlineCode = (text: any) => {
    // regex to match content between single backticks
    const inlineCodeRegex = /`([^`]+)`/g;
    const segments = [];
    let lastIndex = 0;
    let match;

    // find all inline code matches in the text
    while ((match = inlineCodeRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        segments.push({
          type: "text",
          content: text.slice(lastIndex, match.index),
        });
      }

      // add the matched inline code as a code segment
      // match[1] contains the text between the backticks
      segments.push({
        type: "inlineCode",
        content: match[1],
      });

      lastIndex = match.index + match[0].length;
    }

    // add any remaining text after the last inline code
    if (lastIndex < text.length) {
      segments.push({
        type: "text",
        content: text.slice(lastIndex),
      });
    }

    return segments;
  };

  // function to parse the message text and split it into code and non-code parts
  const renderContent = (text: any) => {
    // regex to match code blocks: Looks for text between triple backticks
    const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    // find all code blocks in the text
    while ((match = codeBlockRegex.exec(text)) !== null) {
      // if there's text before the code block, add it as a text part
      if (match.index > lastIndex) {
        parts.push({
          type: "text",
          content: text.slice(lastIndex, match.index),
        });
      }

      // add the code block part with its language (if specified) and content
      parts.push({
        type: "code",
        language: match[1] || "text", // just use 'text' if no language specified
        content: match[2].trim(),
      });

      lastIndex = match.index + match[0].length;
    }

    // add any remaining text after the last code block
    if (lastIndex < text.length) {
      parts.push({
        type: "text",
        content: text.slice(lastIndex),
      });
    }

    return parts;
  };

  // function to render each text segment (inline code or plain text)
  const renderTextSegment = (segment: any, index: any) => {
    if (segment.type === "inlineCode") {
      return (
        <code
          key={index}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "3px",
            padding: "2px 4px",
            fontFamily: '"Fira Code", "Consolas", monospace',
            fontSize: "14px",
            whiteSpace: "pre",
          }}
        >
          {segment.content}
        </code>
      );
    }
    return <span key={index}>{segment.content}</span>;
  };

  // function to render each part (either code or text)
  const renderPart = (part: any, index: any, parts: any) => {
    if (part.type === "code") {
      return (
        <Box key={index} sx={{ width: "100%" }}>
          <SyntaxHighlighter
            language={part.language}
            style={solarizedLight}
            customStyle={{
              margin: 0,
              borderRadius: "4px",
              fontSize: "14px",
              fontFamily: '"Fira Code", "Consolas", monospace',
            }}
          >
            {part.content}
          </SyntaxHighlighter>
        </Box>
      );
    }

    // process text parts for inline code
    const segments = processInlineCode(part.content);
    return (
      <Typography
        key={index}
        component="div"
        whiteSpace="pre-line"
        lineHeight={1.5}
        fontFamily="Inter"
        fontWeight={400}
        fontSize={16}
        sx={{ "& code": { lineHeight: 1.2 } }}
      >
        {segments.map((segment, i) => renderTextSegment(segment, i))}
      </Typography>
    );
  };

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "center", mb: 2 }}>
      {isReceived && (
        <Box sx={{ mt: 1 }}>
          <SystemUserIcon width={32} height={32} />
        </Box>
      )}
      {!isReceived && (
        <Box sx={{ mt: 1 }}>
          <Box width={32} height={32} />
        </Box>
      )}
      <Box
        sx={{
          width: "85%",
          minHeight: 60,
          p: 2,
          backgroundColor: isReceived ? "messages.received" : "messages.sent",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: timeStamp ? "flex-start" : "center",
          border: timeStamp && !isReceived ? "1px solid" : "none",
          borderColor: "primary.weak",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {typeof message === "string"
            ? renderContent(message).map((part, index, parts) =>
                renderPart(part, index, parts),
              )
            : message}
        </Box>
        {timeStamp && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Typography
              whiteSpace="pre-line"
              lineHeight={0}
              fontFamily="Inter"
              fontWeight={400}
              fontSize={10}
            >
              {new Date(timeStamp).toLocaleString()}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MessageComponent;
