import React from "react";
import { Box, Button, Link, Typography } from "@mui/material";

// @ts-expect-error TS(2307): Cannot find module 'assets/bookmark-icon.svg' or i... Remove this comment to see the full error message
import { ReactComponent as BookMarkIcon } from "assets/bookmark-icon.svg";

const MaterialItem = ({ material, setMaterialPreview }: any) => (
  <Box
    sx={{
      width: "80%",
      p: 2,
      backgroundColor: "#F4E8BD",
      borderRadius: 4,
      position: "initial",
    }}
  >
    <Typography
      fontWeight="bold"
      fontFamily="inter"
      sx={{ gap: 1, display: "flex", alignItems: "center" }}
    >
      <BookMarkIcon />
      {material.name}
    </Typography>
    <Button
      sx={{
        mt: 2,
        fontSize: 12,
        textDecoration: "underline",
        textAlign: "left",
        color: "unset",
        padding: 0,
      }}
      variant="text"
      onClick={() => setMaterialPreview(material.link)}
    >
      View material
    </Button>
  </Box>
);

export default MaterialItem;
