import { instance } from "lib/api/utils";
const mediaUpload = (
  { schoolId, formData }: { schoolId: string; formData: FormData },
  token: string,
) => {
  return instance.post<UploadMediaFilesResponse>(
    `/media/?school_id=${schoolId}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
};
const media = {
  upload: mediaUpload,
};

export default media;
