import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import { useAuth0 } from "@auth0/auth0-react";

const SchoolSelector = (props: any) => {
  const { clear, allowAll, schoolId, setSchoolId } = props;
  const { getAccessTokenSilently, user } = useAuth0();
  const [schools, setSchools] = useState<Array<any>>([]);

  const handleChange = (event: any) => {
    setSchoolId(event.target.value);
    if (clear) clear();
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.school.list(
          {
            rowsPerPage: 999,
          },
          token,
        );
        setSchools(response.data.schools);
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    };

    fetchSchools();

    return () => {
      setSchools([]);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="schoolId-selector-label">
        {allowAll && !schoolId ? "All Schools" : "School"}
      </InputLabel>
      <Select
        labelId="schoolId-selector-label"
        id="schoolId-selector"
        value={schoolId}
        label={allowAll && !schoolId ? "All Schools" : "School"}
        onChange={handleChange}
      >
        {allowAll && (
          <MenuItem
            key="undefined"
            value={undefined}
            sx={{ fontStyle: "italic" }}
          >
            All Schools
          </MenuItem>
        )}
        {schools &&
          schools.map((item) => (
            <MenuItem key={item.school.id} value={item.school.id}>
              {item.school.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(SchoolSelector);
