import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useAuth0 } from "@auth0/auth0-react";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import AppLine from "components/teacher/AppLine";
import StudentItem from "components/teacher/StudentItem";
import CustomPaginationBar from "components/teacher/CustomPaginationBar";
import StudentNotesModal from "components/modals/StudentNotesModal";
import CreateUserModal from "components/modals/CreateUserModal";
import Modal from "components/Modal";
import ObjectiveLegend from "./ObjectiveLegend";

const StudentTable = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    noRefresh,
    refreshing,
    currentSchoolId,
    currentSchoolCode,
    currentClassroomId,
    currentClassroomTeacherId,
    selectedActivity,
  } = props;

  const { courseId, activityId } = useParams<{
    courseId?: string;
    activityId?: string;
  }>();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [students, setStudents] = useState<Array<any>>([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [studentNotesModalOpen, setStudentNotesModalOpen] = useState(false);
  const [createStudentModalOpen, setCreateStudentModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState<any | null>(null);
  const [softRefreshing, setSoftRefreshing] = useState(false);

  const toggleStudentNotesModalOpen = () =>
    setStudentNotesModalOpen((prev) => !prev);

  const toggleCreateStudentModalOpen = () =>
    setCreateStudentModalOpen((prev) => !prev);

  const paginateArray = (longArray: any, pageSize = 10) => {
    const paginatedArray = [];
    for (let i = 0; i < longArray.length; i += pageSize) {
      paginatedArray.push(longArray.slice(i, i + pageSize));
    }
    return paginatedArray;
  };

  const handleDeleteClick = (student: any) => {
    setStudentToDelete(student);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    if (studentToDelete) {
      removeStudent(studentToDelete.id);
    }
    setDeleteConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setStudentToDelete(null);
  };

  const removeStudent = async (studentId: any) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await api.classroom.updatePupils(
        {
          classroomId: currentClassroomId,
          removePupils: [studentId],
        },
        token,
      );

      const updatedStudents = students
        .map((page) => page.filter((student: any) => student.id !== studentId))
        .filter((page) => page.length > 0);

      setStudents(updatedStudents);
      props.setSnackbar({
        open: true,
        message: "Student removed successfully",
        severity: "success",
      });
    } catch (e: any) {
      props.setSnackbar({
        open: true,
        message: e.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSoftRefreshing(true);
      setRefresh(true);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // effect for calculating objectives progress
  useEffect(() => {
    if (
      props.setObjectivesProgress &&
      selectedActivity?.objectives &&
      students.length > 0
    ) {
      const allStudents = students.flat(1);
      const progress: { [key: number]: { total: number; completed: number } } =
        {};

      // initialize progress tracking for each objective
      selectedActivity.objectives.forEach((objective: any) => {
        progress[objective.id] = {
          total: allStudents.length,
          completed: 0,
        };
      });

      // count completions for each student
      allStudents.forEach((student: any) => {
        if (student.analytics && student.analytics[0]?.objectives_summary) {
          student.analytics[0].objectives_summary.forEach((objective: any) => {
            const objectiveId = objective.objective_id;
            if (objective.understanding_level === "Full") {
              progress[objectiveId].completed += 1;
            }
          });
        }
      });

      props.setObjectivesProgress(progress);
    }
  }, [students, selectedActivity, props.setObjectivesProgress]);

  useEffect(() => {
    const fetchAnalytics = async (student: any) => {
      try {
        const token = await getAccessTokenSilently();
        if (!activityId)
          throw new Error(
            "Unable to fetch activity analytics. Expected an activityId but got none",
          );
        const response = await api.activities.getAnalytics(
          {
            activityId,
            pupilId: student.id,
          },
          token,
        );

        if (response.data) {
          return [response.data];
        }
        return [];
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
        return [];
      }
    };

    const fetchSentiments = async (student: any) => {
      try {
        const token = await getAccessTokenSilently();

        if (!activityId)
          throw new Error(
            "Unablt to fetch sentiments. Expected an activityId but got none",
          );

        const response = await api.activities.getSentiments(
          {
            activityId,
            pupilId: student.id,
          },
          token,
        );

        return response.data.analytics[0];
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      }
    };

    const fetchNotes = async (student: any) => {
      try {
        const token = await getAccessTokenSilently();
        if (!courseId)
          throw new Error(
            "Unable to fetch notes. Expected course ID and got none.",
          );
        const response = await api.note.get(
          {
            courseId,
            studentId: student.id,
          },
          token,
        );

        return response.data.note.note;
      } catch (e: any) {
        return "";
      }
    };

    const fetchStudents = async () => {
      // only show loading spinner on initial load, not refresh
      if (!softRefreshing && !noRefresh) {
        setLoading(true);
      }

      if (props.setObjectivesProgressLoading) {
        setTimeout(() => {
          props.setObjectivesProgressLoading(true);
        }, 50);
      }

      try {
        const token = await getAccessTokenSilently();
        const response = await api.school.listClassrooms(
          {
            schoolId: currentSchoolId,
            rowsPerPage: 9999,
            pupilsPerPage: 99999,
            teachersPerPage: 99999,
            mine: true,
          },
          token,
        );

        let fetchedStudents = await Promise.all(
          response.data.classrooms
            .find((c: any) => c.classroom.id === currentClassroomId)
            ?.pupils.map(async (student: any) => {
              const notes = await fetchNotes(student);
              let analytics: LatestObjectivesSummaryResponse[] | null = null;
              if (activityId) {
                analytics = await fetchAnalytics(student);
              }

              let sentiment_analytics =
                new Array<UnifiedCourseActivityAnalytics>();
              if (activityId) {
                sentiment_analytics = await Promise.all(
                  selectedActivity?.objectives?.map(
                    async () => await fetchSentiments(student),
                  ),
                );
              }

              return {
                id: student.id,
                name: student.firstName + " " + student.lastName,
                firstName: student.firstName,
                lastName: student.lastName,
                email: student.email,
                profileImageLink: student.profileImageLink,
                notes,
                analytics,
                sentiment_analytics: sentiment_analytics || [],
              };
            }) || [],
        );

        if (viewAll) setStudents([fetchedStudents]);
        else setStudents(paginateArray(fetchedStudents));
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        setLoading(false);
        setSoftRefreshing(false);
        // delay turning off loading states slightly
        setTimeout(() => {
          if (props.setObjectivesProgressLoading)
            props.setObjectivesProgressLoading(false);
          if (props.setSentimentsProgressLoading)
            props.setSentimentsProgressLoading(false);
          if (props.setRefreshing) props.setRefreshing(false);
        }, 300);
      }
    };

    if (!studentNotesModalOpen && !createStudentModalOpen && !refresh) {
      fetchStudents();
    }

    setRefresh(false);
  }, [
    studentNotesModalOpen,
    createStudentModalOpen,
    refresh,
    activityId,
    courseId,
    currentClassroomId,
    currentClassroomTeacherId,
    currentSchoolId,
    getAccessTokenSilently,
    noRefresh,
    props,
    refreshing,
    selectedActivity?.objectives,
    viewAll,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "inputs.light",
        p: 4,
        borderRadius: 2,
        height: "89vh",
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        {(refreshing || softRefreshing) && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 30,
              right: 30,
              gap: 2,
            }}
          >
            <Typography fontFamily="Inter" fontSize={14} color="error">
              Refreshing
            </Typography>
            <CircularProgress size={20} color="error" />
          </Box>
        )}
        <Typography fontFamily="Inter" fontSize={24}>
          Students
        </Typography>
        <Button
          onClick={toggleCreateStudentModalOpen}
          startIcon={<Add />}
          variant="contained"
          sx={{
            borderRadius: 1,
            width: "fit-content",
          }}
        >
          <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
            Add Student
          </Typography>
        </Button>
      </Box>
      <Box sx={{ display: "flex", mb: 2, gap: 1, alignItems: "center" }}>
        {/* Name column - takes up 2 parts */}
        <Box sx={{ flex: 2, display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontFamily="Inter" fontSize={16}>
            Name
          </Typography>
          <ExpandMoreIcon />
        </Box>
        {selectedActivity && (
          <>
            <Box sx={{ flex: 1 }}>
              <Typography fontFamily="Inter" fontSize={16}>
                Sentiment
              </Typography>
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography fontFamily="Inter" fontSize={16}>
                Objective Progress
              </Typography>
            </Box>
          </>
        )}
        <Box sx={{ width: 48 }} /> {/* Spacer for delete button */}
      </Box>
      <AppLine color="inputs.main" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box
          sx={{
            overflowY: "scroll",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress size={120} />
            </Box>
          ) : (
            students &&
            students.length > 0 &&
            students[page - 1].map((student: any, index: any) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  mt: index === 0 ? 1 : "",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flex: 1, display: "flex" }}>
                    <StudentItem
                      student={student}
                      setSelectedStudent={setSelectedStudent}
                      toggleStudentNotesModalOpen={toggleStudentNotesModalOpen}
                      showNotes={true}
                    />
                  </Box>
                  <IconButton
                    onClick={() => handleDeleteClick(student)}
                    color="error"
                    sx={{ ml: 2 }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <AppLine color="lightgray" />
              </Box>
            ))
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CustomPaginationBar
            page={page}
            setPage={setPage}
            length={students?.length}
            viewAll={viewAll}
            toggleViewAll={() => {
              setPage(1);
              if (!viewAll) setStudents([students.flat(1)]);
              else setStudents(paginateArray(students.flat(1)));
              setViewAll((prev) => !prev);
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ObjectiveLegend />
          </Box>
        </Box>
      </Box>
      <StudentNotesModal
        open={studentNotesModalOpen}
        handleClose={toggleStudentNotesModalOpen}
        student={selectedStudent}
      />
      <CreateUserModal
        open={createStudentModalOpen}
        onClose={toggleCreateStudentModalOpen}
        rolesId={4}
        code={currentSchoolCode}
        students={students.flat(1)}
        addToClassroom
      />
      <Modal
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        onSubmit={handleConfirmDelete}
        submitText="Remove"
        cancelText="Cancel"
        maxWidth="md"
        fullWidth
      >
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "background.default" }}
        >
          <Typography variant="body1" paragraph>
            Are you sure you want to remove this student?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              my: 2,
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <Avatar
              src={studentToDelete?.profileImageLink}
              sx={{ width: 48, height: 48, mr: 2 }}
            >
              {studentToDelete?.name.charAt(0)}
            </Avatar>
            <Typography variant="h6">{studentToDelete?.name}</Typography>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  selectedActivity: state.user.selectedActivity,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentTable);
