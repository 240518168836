import React from "react";
import { TextField, Typography, Box } from "@mui/material";

const InputWithLabel = ({
  disabled,
  isRequired = false,
  placeholder,
  label,
  multiline,
  rows,
  value,
  handleValueChange = () => {},
  noLabel,
  sx
}: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginBottom: 2,
        position: "relative",
      }}
    >
      {!noLabel && (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.36px",
            letterSpacing: "0.02em",
            textAlign: "left",
            display: "flex",
            marginBottom: "8px",
          }}
        >
          {label}
          {isRequired && (
            <Typography
              sx={{
                color: "primary.main",
                fontSize: "12px",
                lineHeight: "14.63px",
                letterSpacing: "0.02em",
                ml: 1,
              }}
            >
              *
            </Typography>
          )}
        </Typography>
      )}
      <TextField
        disabled={disabled}
        variant="outlined"
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        required={isRequired}
        onChange={handleValueChange}
        value={value}
        sx={{
          position: "relative",
          backgroundColor: "white",
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
            "&.Mui-disabled fieldset": {
              borderColor: "white",
            },
          },
          ...sx,
        }}
      />
    </Box>
  );
};

export default InputWithLabel;
