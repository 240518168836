import { authInstance, createAuthHeaders, instance } from "lib/api/utils";

const schoolCreate = (
  {
    name,
    address,
    city,
    state,
    zipCode,
    contactEmail,
    contactPhoneNumber,
    planId,
    orgType,
  }: SchoolCreateRequest,
  token: string,
) => {
  return authInstance().post<SchoolResponse>(
    `/schools/`,
    {
      name,
      address,
      city,
      state,
      zipCode,
      contactEmail,
      contactPhoneNumber,
      planId,
      orgType,
    },
    createAuthHeaders(token),
  );
};

const schoolList = async ({ page = 1, rowsPerPage = 10 }, token: string) => {
  try {
    const response = await instance.get<SchoolsResponse>(
      `/schools/?page=${page}&per_page=${rowsPerPage}`,
      createAuthHeaders(token),
    );
    return response;
  } catch (error: any) {
    console.error("Error in schoolList:", error);
    throw error;
  }
};

const schoolGet = ({ schoolId }: { schoolId: string }, token: string) => {
  return authInstance().get<SchoolResponse>(
    `/schools/${schoolId}`,
    createAuthHeaders(token),
  );
};

const schoolUpdate = (
  {
    schoolId,
    name,
    address,
    city,
    state,
    zipCode,
    contactEmail,
    contactPhoneNumber,
    orgType,
  }: SchoolUpdateRequest & { schoolId: string },
  token: string,
) => {
  return authInstance().patch<SchoolResponse>(
    `/schools/${schoolId}`,
    {
      name,
      address,
      city,
      state,
      zipCode,
      contactEmail,
      contactPhoneNumber,
      orgType,
    },
    createAuthHeaders(token),
  );
};

const schoolGenerateCode = (
  { schoolId }: { schoolId: string },
  token: string,
) => {
  return authInstance().post<SchoolWithCodeResponse>(
    `/schools/${schoolId}/code/`,
    createAuthHeaders(token),
  );
};

const schoolListCodes = ({ schoolId }: { schoolId: string }, token: string) => {
  return authInstance().get<SchoolWithCodesResponse>(
    `/schools/${schoolId}/code/`,
    createAuthHeaders(token),
  );
};

const classroomList = (
  {
    schoolId,
    page = 1,
    rowsPerPage = 10,
    teachersPerPage = 10,
    pupilsPerPage = 10,
    mine,
  }: {
    schoolId: string;
    page?: number;
    rowsPerPage?: number;
    teachersPerPage?: number;
    pupilsPerPage?: number;
    mine?: boolean;
  },
  token: string,
) => {
  return authInstance().get<ClassroomsResponse>(
    `/schools/${schoolId}/classrooms/?page=${page}&per_page=${rowsPerPage}&per_page_pupils=${pupilsPerPage}&per_page_teachers=${teachersPerPage}${
      mine ? "&mine=True" : ""
    }`,
    createAuthHeaders(token),
  );
};

const school = {
  create: schoolCreate,
  list: schoolList,
  get: schoolGet,
  update: schoolUpdate,
  generateCode: schoolGenerateCode,
  listCodes: schoolListCodes,
  listClassrooms: classroomList,
};

export default school;
