import { SHOW_STUDENT_CONVERSATION } from "actions/studentConversation";
const initialState = {
  studentConversation: null,
};

export default function studentConversationReducer(
  state = initialState,
  action: any,
) {
  switch (action.type) {
    case SHOW_STUDENT_CONVERSATION:
      return { ...state, studentConversation: action.studentId };
    default:
      return state;
  }
}
