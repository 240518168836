import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Button, Drawer, Toolbar } from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";

import { clearUser } from "reducers/user";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useAuth0 } from "@auth0/auth0-react";
import { handleUserLogout } from "utils/logout";

const Sidenav = (props: any) => {
  const { routes, mobileDrawerOpen, setMobileDrawerOpen } = props;
  const history = useHistory();
  const location = useLocation();
  const width = useWindowWidth();
  const { logout } = useAuth0();

  let boxProps = {};
  if (width < 1200) {
    boxProps = {
      component: Drawer,
      open: mobileDrawerOpen,
      variant: "temporary",
      onClose: () => setMobileDrawerOpen(false),
      sx: { flexGrow: 1 },
      PaperProps: {
        sx: { backgroundColor: "background.sideNav" },
      },
      ModalProps: { keepMounted: true },
    };
  } else {
    boxProps = {
      component: Box,
      sx: { flexGrow: 1 },
    };
  }

  return (
    <Box {...boxProps}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
        }}
      >
        {width < 1200 && <Toolbar />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {routes.map((route: any) => <Button
            key={route.name}
            color="inherit"
            fullWidth
            sx={{
              width: 250,
              color: "#000",
              ...(location.pathname === route.path && {
                backgroundColor: "secondary.dark",
                color: "#FFF",
              }),
              "&:hover": {
                backgroundColor: "secondary.main",
                color: "#FFF",
              },
            }}
            onClick={() => {
              history.push(route.path);
              setMobileDrawerOpen(false);
            }}
            variant="text"
          >
            {route.name}
          </Button>)}
        </Box>
        <Button
          sx={{
            width: 250,
            mt: 10,
            gap: 1,
            color: "secondary.dark",
            ":hover": { color: "error.main" },
          }}
          onClick={() => handleUserLogout(logout, clearUser)}
        >
          <LogoutIcon fontSize="small" />
          Logout
        </Button>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = {
  clearUser,
};

export default connect(null, mapDispatchToProps)(Sidenav);
