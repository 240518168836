import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
} from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

const MAX_MESSAGE_LENGTH = 2000;

const ChatInput = ({
  onSendMessage,
  isLoading
}: any) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef(null);

  const validateMessage = (text: any) => {
    if (!text?.trim()) return "Message cannot be empty";
    if (text.length > MAX_MESSAGE_LENGTH)
      return `Message cannot be longer than ${MAX_MESSAGE_LENGTH} characters`;
    return "";
  };

  const handleSendMessage = () => {
    if (isLoading) return;
    const validationError = validateMessage(message);
    if (validationError) {
      setError(validationError);
      return;
    }

    onSendMessage(message);
    setMessage("");
    setError("");
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <TextField
        inputRef={inputRef}
        multiline
        variant="outlined"
        margin="none"
        fullWidth
        id="message"
        label="Message Ellie"
        name="message"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
          if (error) setError("");
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        error={!!error}
        helperText={error}
        autoFocus
        sx={{
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "white" },
            "&:hover fieldset": { borderColor: "white" },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
          // style the error message here
          "& .MuiFormHelperText-root": {
            fontFamily: "Inter",
            fontSize: "0.875rem",
            marginTop: 1,
            marginLeft: 0,
          },
        }}
        InputLabelProps={{
          sx: {
            fontFamily: "Inter",
            color: "#9FB8D7",
            "&.Mui-focused": { color: "transparent" },
            "&.MuiInputLabel-shrink": { color: "transparent" },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box
                sx={{
                  cursor: message.trim() ? "pointer" : "not-allowed",
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleSendMessage}
              >
                {!isLoading ? (
                  <ArrowUpward
                    sx={{
                      backgroundColor: message.trim()
                        ? "inputs.main"
                        : "#EFF3F8",
                      color: message.trim() ? "white" : "#9FB8D7",
                      borderRadius: "50%",
                      width: 35,
                      height: 35,
                      p: "2px",
                      "&:hover": {
                        backgroundColor: message.trim()
                          ? "inputs.main"
                          : "#EFF3F8",
                      },
                    }}
                  />
                ) : (
                  <CircularProgress size={35} />
                )}
              </Box>
            </InputAdornment>
          ),
          sx: {
            backgroundColor: "background.paper",
            boxShadow: "0px 10px 20px 0px #9FB8D73D",
            fontFamily: "Inter",
          },
        }}
      />
    </Box>
  );
};

export default React.memo(ChatInput);
