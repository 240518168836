import React, { useState } from "react";
import { Box, Button, Icon, IconButton, Typography } from "@mui/material";
import MaterialItem from "./MaterialItem";
import { Close, OpenInBrowser } from "@mui/icons-material";

const EmbossedCard = ({
  materials,
  selectedActivity,
  materialPreview,
  setMaterialPreview,
}: any) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F7ECC5",
        height: "87vh",
        mt: 4,
        overflowY: "auto",
        borderTopLeftRadius: "8px",
      }}
    >
      {materialPreview && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingY: "1rem",
            paddingX: "1rem",
            gap: "1rem",
            flexBasis: "1",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight="bold" fontFamily="inter">
              Material Preview
            </Typography>
            <IconButton onClick={() => setMaterialPreview(null)}>
              <Close />
            </IconButton>
          </Box>
          <Button
            variant="text"
            color="secondary"
            sx={{
              width: "fit-content",
              display: "flex",
              gap: "0.5rem",
              padding: 0,
            }}
            size="small"
            onClick={() => window.open(materialPreview, "_blank")}
          >
            Open in new Tab
            <OpenInBrowser />
          </Button>
          <iframe
            src={materialPreview}
            style={{ border: "none", outline: "none", height: "100%" }}
          />
        </Box>
      )}
      {!materialPreview && (
        <Box>
          <Box>
            <Typography
              fontFamily="Inter"
              fontWeight="bold"
              fontSize={16}
              pl={3}
              py={2}
              // border="1px solid"
              sx={{ width: "100%" }}
            >
              Activity Materials
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              overflowY: "auto",
              gap: 3,
              position: "relative",
            }}
          >
            {materials
              ?.filter(
                (el: any) => el.activityId == selectedActivity.activity.id,
              )
              .map((material: any, index: any) => (
                <MaterialItem
                  key={index}
                  material={material}
                  setMaterialPreview={setMaterialPreview}
                />
              ))}
          </Box>

          <Box>
            <Typography
              fontFamily="Inter"
              fontWeight="bold"
              fontSize={16}
              pl={3}
              py={2}
              // border="1px solid"
              sx={{ width: "100%" }}
            >
              Course Materials
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              overflowY: "auto",
              gap: 3,
              position: "relative",
            }}
          >
            {materials
              ?.filter((el: any) => !el.activityId)
              .map((material: any, index: any) => (
                <MaterialItem
                  key={index}
                  material={material}
                  setMaterialPreview={setMaterialPreview}
                />
              ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmbossedCard;
