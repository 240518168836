import React from "react";
import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";

import { setSelectedActivity } from "reducers/user";

const ActivityItem = (props: any) => {
  const { activity, summary } = props;

  return (
    <Box
      onClick={() => props.setSelectedActivity(activity)}
      sx={{
        p: 1,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        ":hover": {
          backgroundColor: "rgba(255,255,255,0.33)",
        },
        position: "relative",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: 100,
          height: "100%",
          top: 0,
          right: 0,
          zIndex: 2,
          background:
            "linear-gradient(270deg, #EEF2F8 36.5%, rgba(255, 255, 255, 0) 100%)",
        }}
      />
      <Typography
        variant="subtitle1"
        color="primary.main"
        fontSize={18}
        fontFamily="Inter"
        sx={{ position: "relative", zIndex: 3 }}
      >
        {activity.activity.name}
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Inter"
        lineHeight={2}
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 4,
          textOverflow: "ellipsis",
          position: "relative",
          zIndex: 1,
        }}
      >
        {summary}
      </Typography>
    </Box>
  );
};

const mapDispatchToProps = {
  setSelectedActivity,
};

export default connect(null, mapDispatchToProps)(ActivityItem);
