import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import DeleteModal from "components/modals/DeleteModal";
import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import { setSelectedActivity } from "reducers/user";
import InputWithLabel from "components/InputWithLabel";
import ParagraphSection from "components/ParagraphSection";
import LlmRoleSelector from "components/selectors/LlmRoleSelector";
import UploadMaterialModal from "components/modals/UploadMaterialModal";
import MaterialList from "components/teacher/MaterialList";
import Insight from "components/teacher/Insight";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Page for adding or editing an activity.
 * @param {Object} props component props
 * @param {boolean} props.selectedActivity whether the user is editing an existing activity
 * @param {string} props.currentSchoolId current school id
 * @param {string} props.currentClassroomTeacherId current classroom teacher id
 * @param {string} props.courseId course id
 * @param {string} [props.activityId] activity id to edit
 * @param {Function} props.setSnackbar function to set the snackbar message
 * @param {Function} props.setSelectedActivity function to set the selected activity
 * @returns {ReactElement} The rendered AddActivity component
 */
const AddActivity = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedActivity, currentSchoolId, currentClassroomTeacherId } =
    props;
  const history = useHistory();
  const { courseId, activityId } = useParams<{
    courseId?: string;
    activityId?: string;
  }>();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [promptInstructions, setPromptInstructions] = useState("");
  const [llmRolesId, setLlmRolesId] = useState("");
  const [objectives, setObjectives] = useState<
    Array<{
      details: string;
      new: boolean;
      id?: string;
    }>
  >([
    {
      details: "",
      new: false,
    },
  ]);
  const [removeObjectives, setRemoveObjectives] = useState([] as number[]);
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newMaterials, setNewMaterials] = useState<Array<any>>([]); // these are materials that have been created prior to saving the activity

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen);

  const handleAdd = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();

      if (!courseId)
        throw new Error(
          "Unable to create activity. Expected a course ID but got none",
        );

      const response = await api.activities.createActivity(
        {
          coursesId: Number(courseId),
          name,
          description: "",
          promptInstructions,
          llmRolesId: Number(llmRolesId),
          objectives,
          questions: [],
        },
        token,
      );

      if (newMaterials.length) {
        for (const material of newMaterials) {
          material.activityId = response.data.id;
          await api.material.update({ material }, token);
        }
      }

      history.push(`/class/${courseId}`);
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (!activityId)
        throw new Error(
          "Unable to update activity. Expected an activity ID but got none",
        );
      const response = await api.activity.update(
        {
          activityId,
          name,
          description: "",
          promptInstructions,
          llmRolesId,
        },
        token,
      );

      await api.activity.updateObjectives(
        {
          activityId,
          objectives: objectives.reduce((acc, el) => {
            if (el.new || !el.id) return acc;
            acc.push({ id: el.id, details: el.details });
            return acc;
          }, new Array<{ id: string; details: string }>()),
        },
        token,
      );

      await api.activity.addObjectiveOrQuestion(
        {
          activityId,
          objectives: objectives
            .filter((objective) => objective.new)
            .map((objective) => ({
              details: objective.details,
            })),
        },
        token,
      );

      if (removeObjectives.length > 0)
        if (!courseId)
          throw new Error(
            "Unable to update activities and materials. Expected course ID and got none",
          );
        else
          await api.course.updateActivitiesAndMaterials(
            {
              courseId: courseId!,
              removeObjectives,
            },
            token,
            0,
          );

      props.setSelectedActivity(response.data);
      history.push(`/class/${courseId}`);
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const addObjective = () => {
    setObjectives([
      ...objectives,
      {
        details: "",
        new: true,
        id: undefined,
      },
    ]);
  };

  const removeObjective = (index: any) => {
    setObjectives(objectives.filter((_, i) => i !== index));

    const removedObjective = objectives[index];
    if (removedObjective.id) {
      setRemoveObjectives((prev) => [...prev, Number(removedObjective.id)]);
    }
  };

  const updateObjective = (value: any, index: any) => {
    const updatedObjectives = objectives.map((objective, i) =>
      i === index ? { ...objective, details: value } : objective,
    );
    setObjectives(updatedObjectives);
  };

  useEffect(() => {
    if (selectedActivity) {
      setName(selectedActivity?.activity?.name || "");
      setPromptInstructions(
        selectedActivity?.activity?.promptInstructions || "",
      );
      setLlmRolesId(selectedActivity?.activity?.llmRole.id || "");
      setObjectives(
        selectedActivity?.objectives || [
          {
            details: "",
          },
        ],
      );
    }
  }, [selectedActivity]);

  return (
    <Grid
      container
      sx={{ pt: 2, px: 2, mb: 4, justifyContent: "center" }}
      spacing={4}
    >
      <Grid item xs={12} lg={4} mb={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography fontFamily="Inter">
            Think of activities as a worksheet for the day. Each class can have
            multiple activities that align with objectives.
          </Typography>
          <Insight>
            <ParagraphSection
              title="Activity Title"
              description=" Provide a concise title, this will help you and your students quickly identify the task."
            />
            <ParagraphSection
              title="Instructions"
              description=" Write a brief description of the activity. Include any relevant instructions or important details that students need to know before starting."
            />
            <ParagraphSection
              title="Ellie’s Role"
              description="This text provides insight as to how to guide and assist students through each activity."
            />
            <ParagraphSection
              title="Objectives"
              description="Define what students should learn or achieve by completing the activity. Clearly defined objectives will guide students and ensure that the activity meets educational goals."
            />

            <Typography
              fontWeight="Bold"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              View Tips for Creating Effective Activities & Objectives
            </Typography>
          </Insight>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4} mb={4}>
        <Box
          sx={{
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWithLabel
                label="Activity Title"
                value={name}
                handleValueChange={(e: any) => setName(e.target.value)}
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <InputWithLabel
                multiline
                label="Instructions"
                value={promptInstructions}
                handleValueChange={(e: any) =>
                  setPromptInstructions(e.target.value)
                }
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <LlmRoleSelector
                llmRolesId={llmRolesId}
                setLlmRolesId={setLlmRolesId}
              />
            </Grid>
            <Grid item xs={12}>
              {objectives.map((objective, index) => (
                <Box key={index} sx={{ position: "relative" }}>
                  <InputWithLabel
                    multiline
                    label="Objectives"
                    value={objective.details}
                    handleValueChange={(e: any) =>
                      updateObjective(e.target.value, index)
                    }
                    fullWidth
                    noLabel={index > 0}
                    isRequired
                  />
                  {index > 0 && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        backgroundColor: "rgba(255,255,255,0.8)",
                      }}
                      onClick={() => removeObjective(index)}
                    >
                      <Close />
                    </IconButton>
                  )}
                </Box>
              ))}
              <Button
                onClick={addObjective}
                color="primary"
                variant="outlined"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  borderRadius: 1,
                  mb: 1,
                }}
                startIcon={<Add />}
              >
                Add Objective
              </Button>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: 2,
                backgroundColor: "background.sideNav",
                mt: 2,
                ml: 2,
              }}
            />
            <Box sx={{ width: "100%", pl: 2, mt: 1 }}>
              <MaterialList
                courseId={courseId}
                setSelectedMaterial={setSelectedMaterial}
                materialDialogOpen={materialDialogOpen}
                toggleOpenDialog={toggleMaterialDialogOpen}
                materials={newMaterials}
                addingActivity={true}
                activityId={activityId}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: 2,
                backgroundColor: "background.sideNav",
                mt: 2,
                ml: 2,
              }}
            />
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
            {selectedActivity && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
                sx={{ borderRadius: 1 }}
              >
                <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                  Delete Activity
                </Typography>
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={async () => {
                const token = await getAccessTokenSilently();
                await Promise.all(
                  newMaterials.map((el) =>
                    api.material.delete({ materialId: el.id }, token),
                  ),
                );
                history.push(`/class/${courseId}`);
              }}
              sx={{ borderRadius: 1 }}
            >
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                Cancel
              </Typography>
            </Button>
            <Button
              disabled={
                loading ||
                name === "" ||
                promptInstructions === "" ||
                llmRolesId === "" ||
                objectives[0].details === ""
              }
              onClick={selectedActivity ? handleEdit : handleAdd}
              color="primary"
              variant="contained"
              sx={{ borderRadius: 1 }}
            >
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                Save Activity
              </Typography>
            </Button>
          </Box>
        </Box>
      </Grid>
      <UploadMaterialModal
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        courseId={courseId}
        open={materialDialogOpen}
        onClose={toggleMaterialDialogOpen}
        activityId={activityId}
        concatMaterials={(materials: any) =>
          setNewMaterials((prev) => [...prev, ...materials])
        }
        creatingActivity={!selectedActivity}
      />
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        itemTitle={selectedActivity?.activity.name}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        deleteFunction={async () => {
          setDeleteModalOpen(false);
          await api.activity
            .delete(
              {
                activityId: selectedActivity?.activity.id,
              },
              await getAccessTokenSilently(),
            )
            .then(() => {
              props.setSnackbar({
                severity: "success",
                message: "Successfully deleted activity",
              });
              history.push(`/class/${courseId}`);
            });
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSnackbar,
  setSelectedActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddActivity);
