import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { setSnackbar } from "reducers/ui";
import StudentTable from "components/teacher/StudentTable";
import ActivityItem from "components/teacher/ActivityItem";
import api from "lib/api";
import MessageComponent from "components/student/MessageComponent";
import StudentChatPreview from "components/teacher/StudentChatPreview";

const ActivityDashboard = (props: any) => {
  const {
    selectedActivity,
    currentSchoolId,
    currentClassroomId,
    currentClassroomTeacherId,
    currentStudentId,
  } = props;

  const [refreshing, setRefreshing] = useState(false);
  const [objectivesProgress, setObjectivesProgress] = useState<Array<any>>([]);
  const [sentimentsProgress, setSentimentsProgress] = useState<Array<any>>([]);
  const [objectivesProgressLoading, setObjectivesProgressLoading] = //* changed
    useState(false);
  const [sentimentsProgressLoading, setSentimentsProgressLoading] =
    useState(false);
  const studentConversation = useSelector(
    (state: any) => state.studentConversation,
  );
  const { getAccessTokenSilently } = useAuth0();
  const [studentChatHistory, setStudentChatHistory] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchStudentConversation = async () => {
      const token = await getAccessTokenSilently();
      const response = await api.activity.getStudentChatHistory(
        {
          activityId: selectedActivity.activity.id,
          studentId: studentConversation.studentConversation.id,
          page: 1,
          perPage: 9999, // todo this is just here to ignore pagination for now
        },
        token,
      );

      setStudentChatHistory(response.data.chatHistory.toReversed());
    };

    if (studentConversation?.studentConversation) fetchStudentConversation();
  }, [studentConversation]);

  return (
    <Grid container sx={{ pt: 2, px: 2, mb: 4, justifyContent: "center" }}>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{ justifyContent: "center", width: 400, mb: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            overflow: "hidden",
            pr: 2,
          }}
        >
          {studentConversation.studentConversation ? (
            <StudentChatPreview studentChatHistory={studentChatHistory} />
          ) : (
            <>
              <Typography fontFamily="Inter" fontSize={22}>
                Objectives
              </Typography>

              <ActivityItem
                item={selectedActivity}
                refreshing={refreshing}
                objectivesProgress={objectivesProgress}
                objectivesProgressLoading={objectivesProgressLoading}
                sentimentsProgress={sentimentsProgress}
                sentimentsProgressLoading={sentimentsProgressLoading}
                open={true}
              />
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} lg={5} mb={4} sx={{ px: { xs: 2, lg: 0 } }}>
        <StudentTable
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          setObjectivesProgress={setObjectivesProgress}
          setObjectivesProgressLoading={setObjectivesProgressLoading}
          setSentimentsProgress={setSentimentsProgress}
          setSentimentsProgressLoading={setSentimentsProgressLoading}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  currentStudentId: state.user.id,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDashboard);
