import React from "react";

const TypingIndicator = () => {
  // Inline styles for the animated dots
  const dotStyle = {
    display: "inline-block",
    width: "8px",
    height: "8px",
    margin: "0 2px",
    backgroundColor: "#333",
    borderRadius: "50%",
    animation: "bounce 1.4s infinite ease-in-out both",
  };

  // Individual dot animations with delays
  const dot1Style = { ...dotStyle, animationDelay: "0s" };
  const dot2Style = { ...dotStyle, animationDelay: "0.2s" };
  const dot3Style = { ...dotStyle, animationDelay: "0.4s" };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={dot1Style}></span>
      <span style={dot2Style}></span>
      <span style={dot3Style}></span>

      {/* Keyframes for the animation */}
      <style>
        {`
          @keyframes bounce {
            0%, 80%, 100% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default TypingIndicator;
