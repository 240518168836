import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import { useAuth0 } from "@auth0/auth0-react";

const ClassroomTeacherSelector = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    disabled,
    schoolId,
    wholeUser,
    filterOut,
    classroomTeacherIds,
    classroomTeacherId,
    setClassroomTeacherId,
  } = props;
  const [teachers, setTeachers] = useState<Array<any>>([]);

  const handleChange = (event: any) => {
    setClassroomTeacherId(event.target.value);
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      const token = await getAccessTokenSilently();
      try {
        if (schoolId && classroomTeacherIds) {
          const response = await api.school.listClassrooms(
            {
              schoolId: schoolId,
              rowsPerPage: 999,
              teachersPerPage: 999,
            },
            token,
          );
          let teachersWithClassroomTeacherIds: any = [];
          response.data.classrooms.forEach((item: any) => {
            item.teachers.forEach((teacher: any) =>
              teachersWithClassroomTeacherIds.push(teacher),
            );
          });
          setTeachers(teachersWithClassroomTeacherIds);
          if (teachersWithClassroomTeacherIds.length === 0)
            setClassroomTeacherId();
        } else {
          const response = await api.user.list(
            {
              roleId: "3",
              schoolId,
              rowsPerPage: 999,
            },
            token,
          );
          if (filterOut) {
            setTeachers(
              response.data.users.filter(
                (teacher: any) => !filterOut.includes(teacher.user.id),
              ),
            );
          } else setTeachers(response.data.users);
        }
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    };

    fetchTeachers();

    return () => {
      setTeachers([]);
    };

    // eslint-disable-next-line
  }, [schoolId]);

  return (
    <FormControl fullWidth>
      <InputLabel id="classroomTeacherId-selector-label">
        Classroom Teacher
      </InputLabel>
      <Select
        disabled={disabled}
        labelId="classroomTeacherId-selector-label"
        id="classroomTeacherId-selector"
        value={classroomTeacherId}
        label="Classroom Teacher"
        onChange={handleChange}
      >
        {teachers &&
          teachers.map((teacher, index) => (
            <MenuItem
              key={index}
              value={
                wholeUser
                  ? teacher.user
                  : classroomTeacherIds
                    ? teacher.classroomTeacherId
                    : teacher.user.id
              }
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <Typography>
                {teacher.user ? teacher.user.firstName : teacher.firstName}
                {teacher.user ? teacher.user.lastName : teacher.lastName}
              </Typography>
              {classroomTeacherIds && (
                <Typography variant="caption">{teacher.subject}</Typography>
              )}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(ClassroomTeacherSelector);
