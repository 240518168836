/**
 * Handles user logout by clearing local storage, Redux state, and Auth0 session
 * @param {Function} logout - Auth0 logout function
 * @param {Function} clearUser - Redux action to clear user state
 * @returns {void}
 *
 * @example
 * // Usage with Auth0 and Redux
 * handleUserLogout(logout, clearUser);
 *
 * @throws {Error} If Auth0 logout fails
 */
export const handleUserLogout = (logout: any, clearUser: any) => {
  // clear local storage
  localStorage.clear();

  // clear edux state
  clearUser();

  // logout from Auth0
  logout({
    logoutParams: {
      returnTo: window.location.origin,
      federated: false,
    },
  });
};
