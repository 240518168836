import React, { useState } from "react";
import { connect } from "react-redux";
import { TextField, Link, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AuthLayout from "layouts/Auth";
import { setSnackbar } from "reducers/ui";
import { validateEmail } from "lib/helpers";

const ForgotPasswordPage = (props: any) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      alert("Password reset email sent");
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout title="Forgot Password">
      <TextField
        label="Email"
        variant="outlined"
        size="small"
        fullWidth
        sx={{ mt: 2, mb: 3, minWidth: 350 }}
        InputProps={{
          sx: {
            backgroundColor: "background.paper",
          },
        }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleForgotPassword();
        }}
      />

      <LoadingButton
        disabled={loading || Boolean(validateEmail(email) !== true)}
        loading={loading}
        sx={{
          color: "primary.contrastText",
          fontFamily: "Inter",
          fontWeight: "bold",
          fontSize: 14,
          my: 1,
          borderRadius: 1,
        }}
        color="primary"
        variant="contained"
        onClick={handleForgotPassword}
      >
        Send Password Reset Email
      </LoadingButton>

      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item>
          <Link
            href="/student/create"
            variant="body2"
            fontFamily="Inter"
            color="secondary.dark"
          >
            Don't have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);
