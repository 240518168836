import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const ObjectiveWithProgress = ({
  loading,
  description,
  classProgress,
  totalStudents
}: any) => {
  const [localProgress, setLocalProgress] = useState(0);

  useEffect(() => {
    if (!loading && classProgress !== undefined && totalStudents > 0) {
      // calculate percentage of students who completed this objective
      const progressPercentage = (classProgress / totalStudents) * 100;
      setLocalProgress(progressPercentage);
    }
  }, [loading, classProgress, totalStudents]);

  return (
    <Box
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "primary.weak",
        width: "90%",
        alignSelf: "center",
        my: 1,
        pt: 4,
      }}
    >
      <Typography
        fontFamily="Inter"
        fontSize={14}
        sx={{ paddingLeft: 4, paddingRight: 4, marginBottom: 4 }}
      >
        {description}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: 10,
          backgroundColor: "inputs.main",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: `${localProgress}%`,
            height: "100%",
            backgroundColor: "primary.main",
            transition: "width 0.3s ease-in-out",
          }}
        />
      </Box>
    </Box>
  );
};

export default ObjectiveWithProgress;
