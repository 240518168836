import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Button, TableCell, Typography } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import EnhancedTable from "components/DataTable";
import CreateAdminModal from "components/modals/CreateAdminModal";
import EditAdminModal from "components/modals/EditAdminModal";

const AdminPage = (props: any) => {
  const [admins, setAdmins] = useState([] as any[]);
  const [createAdminModalOpen, setCreateAdminModalOpen] = useState(false);
  const [editAdminModalOpen, setEditAdminModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleCreateAdminModalOpen = () => {
    setCreateAdminModalOpen(!createAdminModalOpen);
  };

  const toggleEditAdminModalOpen = () => {
    setEditAdminModalOpen(!editAdminModalOpen);
  };

  const rowCells = (row: any) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">{row.email}</TableCell>
      <TableCell align="right">{row.firstName}</TableCell>
      <TableCell align="right">{row.lastName}</TableCell>
      <TableCell align="right">{row.master ? "YES" : "NO"}</TableCell>
    </>
  );

  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "firstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: true,
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "master",
      numeric: true,
      disablePadding: false,
      label: "Master",
    },
  ];

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await api.admin.list({
          page: page + 1,
          rowsPerPage,
        });
        if (response.data?.admins?.length === 0 && page > 0)
          setPage((page) => page - 1);
        else if (
          response.data?.admins?.length > 0 &&
          !(admins.length > rowsPerPage * page)
        )
          if (page > 0)
            setAdmins((admins) =>
              [...admins, ...response.data.admins].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id),
              ),
            );
          else setAdmins(response.data.admins);
        else if (response.data?.admins?.length > 0 && page === 0)
          setAdmins(response.data.admins);
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          message: `Error fetching admins: ${error.message}`,
          severity: "error",
        });
      }
    };

    if (!createAdminModalOpen && !editAdminModalOpen) fetchAdmins();
    // eslint-disable-next-line
  }, [createAdminModalOpen, editAdminModalOpen, page, rowsPerPage]);

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Admins
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleCreateAdminModalOpen}
        >
          Create Admin
        </Button>
      </Box>

      <EnhancedTable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        filterDisabled
        selectionDisabled
        rows={admins}
        rowCells={rowCells}
        headCells={headCells}
        onRowClick={(admin: any) => {
          setSelectedAdmin(admin);
          toggleEditAdminModalOpen();
        }}
      />

      <CreateAdminModal
        open={createAdminModalOpen}
        onClose={toggleCreateAdminModalOpen}
      />

      <EditAdminModal
        selectedAdmin={selectedAdmin}
        open={editAdminModalOpen}
        onClose={toggleEditAdminModalOpen}
      />
    </Box>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(AdminPage);
