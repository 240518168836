import axios from "axios";
import { store } from "store";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message)
      error.message = error.response.data.message;

    return Promise.reject(error);
  },
);

export const createAuthHeaders = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};

export const authInstance = () => {
  const state = store.getState();
  const authToken = state.user.token;

  if (authToken) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
  }

  return instance;
};
