import { authInstance, createAuthHeaders } from "lib/api/utils";

const inviteCreate = (
  {
    schoolId,
    email,
    expiresInDays,
  }: { schoolId: string } & SchoolInviteTeacherRequest,
  token: string,
) => {
  return authInstance().post<SchoolWithInviteEmailResponse>(
    `/schools/${schoolId}/invite/`,
    {
      email,
      expiresInDays,
    },
    createAuthHeaders(token),
  );
};

const inviteList = ({ schoolId }: { schoolId: string }, token: string) => {
  return authInstance().get<SchoolWithInvitesResponse>(
    `/schools/${schoolId}/invite/`,
    createAuthHeaders(token),
  );
};

const invite = {
  create: inviteCreate,
  list: inviteList,
};
export default invite;
