import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  SaveOutlined,
  ArrowUpward,
  QueryBuilderOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import api from "lib/api";
import { setFlaggedCourses, setSelectedActivity } from "reducers/user";
import { setSnackbar } from "reducers/ui";
// @ts-expect-error TS(2307): Cannot find module 'assets/hand-icon.svg' or its c... Remove this comment to see the full error message
import { ReactComponent as HandIcon } from "assets/hand-icon.svg";
// @ts-expect-error TS(2307): Cannot find module 'assets/paper-clip-icon.svg' or... Remove this comment to see the full error message
import { ReactComponent as PaperClipIcon } from "assets/paper-clip-icon.svg";
import ActivitiesList from "components/student/ActivitiesList";
import MessageComponent from "components/student/MessageComponent";
import EmbossedCard from "components/student/EmbossedCard";
import TypingIndicator from "components/student/TypingIndicator";
import { useAuth0 } from "@auth0/auth0-react";
import RecommendedChatOptions from "components/student//RecommendedChatOptions";
import ChatInput from "components/student/ChatInput";

const Chat = (props: any) => {
  const { selectedCourse, selectedActivity } = props;

  const history = useHistory();
  const isDesktop = useMediaQuery((theme) =>
    (theme as any)?.breakpoints.up("md"),
  );

  const messageBoxRef = useRef<Element | null>();
  const [memo, setMemo] = useState("");
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([] as Array<any>);
  const [currentMessage, setCurrentMessage] = useState("");
  const [activities, setActivities] = useState([] as Array<any>);
  const [materials, setMaterials] = useState([] as Array<any>);
  const [showMaterials, setShowMaterials] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [activitiesLoading, setActivitiesLoading] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const [showRecommendedOptions, setShowRecommendedOptions] = useState(false);
  const [chatHistoryLoaded, setChatHistoryLoaded] = useState(false);

  const getChatHistory = async (pageNumber: any, activityId?: string) => {
    if (page && pageNumber > 1) setMoreLoading(true);
    else setChatLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.activity.getChatHistory(
        {
          activityId: activityId ? activityId : selectedActivity?.activity?.id,
          page: pageNumber,
          perPage: activityId ? 1 : 10,
        },
        token,
      );

      if (activityId) {
        return response?.data?.chatHistory[0]
          ? response.data.chatHistory[0]
          : {
              coursesActivitiesId: activityId,
              summary: "No summary available.",
            };
      }

      // Check if we've received fewer items than requested
      if (response.data.chatHistory.length < 10) {
        setHasMore(false);
      }

      if (pageNumber > 1) {
        setChatHistory((prev) => [...prev, ...response.data.chatHistory]);
      } else {
        setChatHistory(response.data.chatHistory);
        setShowRecommendedOptions(response.data.chatHistory.length === 0);
      }
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setCurrentMessage("");
      if (page && pageNumber > 1) setTimeout(() => setMoreLoading(false), 600);
      else setChatLoading(false);
    }
  };

  const sendMessage = async (message: string) => {
    setChatLoading(true);
    setMessage("");
    setShowRecommendedOptions(false);

    try {
      const token = await getAccessTokenSilently();
      setCurrentMessage("");

      const extractedId = chatHistory.length > 0 ? chatHistory[0].id + 1 : 0;

      const latestChatMessage = {
        id: extractedId,
        coursesActivitiesId: 0,
        pupilsId: props.userId,
        sender: "human",
        message: message,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      setChatHistory((prev) => [
        {
          id: extractedId + 1,
          coursesActivitiesId: 0,
          pupilsId: props.userId,
          sender: "system",
          message: <TypingIndicator />,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        latestChatMessage,
        ...prev,
      ]);

      const response = await api.activity.sendMessage(
        {
          activityId: selectedActivity.activity.id,
          message,
        },
        token,
      );

      if (response.status === 429) {
        const error = await response.json();

        props.setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
        return;
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        if (!reader) throw new Error("Expected a response body but got none");
        const { done, value } = await reader.read();
        if (done) {
          console.log("Stream finished.");
          break;
        }

        buffer += decoder.decode(value, { stream: true });

        // Process each line in the buffer (each line is a complete JSON object)
        const lines = buffer.split("\n");

        // Process each complete JSON line
        for (let i = 0; i < lines.length - 1; i++) {
          try {
            const jsonObject = JSON.parse(lines[i]);
            setCurrentMessage(jsonObject?.message);
          } catch (e: any) {
            console.error("Error parsing JSON:", e);
          }
        }

        // Keep the incomplete line in the buffer for the next chunk
        buffer = lines[lines.length - 1];
      }
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setMessage("");
      getChatHistory(1);
      setPage(1);
      setChatLoading(false);
      setShowRecommendedOptions(false);
    }
  };

  const onScrollToTop = () => {
    setPage((prev) => {
      getChatHistory(prev + 1);
      return prev + 1;
    });
  };

  const handleRecommendedOption = (option: string) => {
    sendMessage(option);
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!messageBoxRef.current) return;

      // only trigger if we're near top and not already loading and have more data
      if (messageBoxRef.current.scrollTop < 50 && !moreLoading && hasMore) {
        onScrollToTop();
      }
    }, 150); // 150ms debounce

    const boxElement = messageBoxRef.current;
    if (boxElement) {
      boxElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (boxElement) {
        boxElement.removeEventListener("scroll", handleScroll);
        handleScroll.cancel(); // cancel any pending debounced calls
      }
    };
  }, [messageBoxRef, selectedActivity, moreLoading, hasMore]);

  useEffect(() => {
    if (selectedActivity) {
      if (messageBoxRef.current) messageBoxRef.current.scrollTop = 0;
      setPage(1);
      setHasMore(true); // reset hasMore when switching activities
      getChatHistory(1);
    }
    // eslint-disable-next-line
  }, [selectedActivity]);

  useEffect(() => {
    const fetchActivitiesAndMaterials = async () => {
      setActivitiesLoading(true);

      try {
        const token = await getAccessTokenSilently();
        const response = await api.course.get(
          {
            courseId: selectedCourse.id,
          },
          token,
        );

        const a = response.data.activities;
        const m = response.data.materials;

        const summaries = await Promise.all(
          a.map(
            async (activity: any) =>
              await getChatHistory(1, activity.activity.id),
          ),
        );

        setActivities(
          a.map((activity: any) => ({
            ...activity,

            activity: {
              ...activity.activity,
              summary: summaries.find(
                (s) => s?.coursesActivitiesId === activity.activity.id,
              )?.summary,
            },
          })),
        );
        setMaterials(m);

        if (a.length > 0 && !selectedActivity) props.setSelectedActivity(a[0]);
        else if (a.length === 0 && selectedActivity) {
          props.setFlaggedCourses([...props.flaggedCourses, selectedCourse.id]);
          history.goBack();
        }
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        setActivitiesLoading(false);
      }
    };

    if (selectedCourse) fetchActivitiesAndMaterials();
    // eslint-disable-next-line
  }, [selectedCourse]);

  const [materialPreview, setMaterialPreview] = useState<string | null>(null);

  return (
    <Grid
      container
      sx={{
        pt: 2,
        pl: 2,
        justifyContent: "center",
        height: "90vh",
      }}
      spacing={2}
    >
      <Grid item sm={0} lg={2} />
      <Grid item sm={12} lg={10}>
        {!isDesktop && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            {showMaterials ? (
              <Typography
                variant="h6"
                fontFamily="Inter"
                fontWeight="bold"
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => setShowMaterials(!showMaterials)}
              >
                Hide Materials
              </Typography>
            ) : (
              <SaveOutlined onClick={() => setShowMaterials(!showMaterials)} />
            )}

            {showActivities ? (
              <Typography
                variant="h6"
                fontFamily="Inter"
                fontWeight="bold"
                onClick={() => setShowActivities(!showActivities)}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Hide Activities
              </Typography>
            ) : (
              <QueryBuilderOutlined
                onClick={() => setShowActivities(!showActivities)}
              />
            )}
          </Box>
        )}

        {isDesktop && (
          <Grid container>
            <Grid item sm={2.5} sx={{ display: materialPreview ? "none" : "" }}>
              {activitiesLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
                  <CircularProgress size={70} />
                </Box>
              ) : (
                <ActivitiesList
                  activities={activities}
                  selectedCourse={selectedCourse}
                  memo={memo}
                  setMemo={setMemo}
                />
              )}
            </Grid>
            <Grid item sm={7} sx={{ position: "relative", pr: 1 }}>
              <Box
                sx={{
                  width: "95%",
                  height: 100,
                  top: -40,
                  background:
                    "linear-gradient(180deg, rgba(239, 243, 248, 1) 5%, rgba(237, 242, 248, 0.9) 40%, rgba(237, 242, 248, 0) 90%)",
                  position: "absolute",
                  zIndex: 10,
                  display: "flex",
                  justifyContent: "center",
                  pt: 2,
                }}
              >
                {moreLoading && <CircularProgress sx={{ ml: 1 }} />}
              </Box>
              <Box
                ref={messageBoxRef}
                sx={{
                  width: "100%",
                  height: "77vh",
                  overflowY: "scroll",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column-reverse",
                  pt: 10,
                  mt: -4,
                }}
              >
                {chatHistory.map((chat, index) => {
                  if (currentMessage.length > 0 && 0 === index)
                    return (
                      <MessageComponent
                        key={`${index}-student-message`}
                        message={currentMessage}
                        isReceived={true}
                      />
                    );

                  return (
                    <MessageComponent
                      key={index}
                      message={chat.message}
                      isReceived={chat.sender === "system"}
                    />
                  );
                })}
              </Box>
              <Box sx={{ width: "100%", p: 2, pb: 0, position: "relative" }}>
                {showRecommendedOptions && !chatLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "100%",
                      left: 0,
                      width: "100%",
                      zIndex: 20,
                      p: 2,
                      pb: 0,
                    }}
                  >
                    <RecommendedChatOptions
                      onSelectOption={handleRecommendedOption}
                    />
                  </Box>
                )}
                <Box sx={{ display: "flex", gap: 1 }}></Box>
                <ChatInput
                  onSendMessage={sendMessage}
                  isLoading={chatLoading}
                />
              </Box>
            </Grid>

            <Grid
              item
              sm={2}
              md={materialPreview ? 5 : 2.5}
              sx={{ position: "relative" }}
            >
              <EmbossedCard
                materials={materials}
                selectedActivity={selectedActivity}
                materialPreview={materialPreview}
                setMaterialPreview={setMaterialPreview}
              />
            </Grid>
          </Grid>
        )}

        {!isDesktop && (
          <Grid container p={0.1} sx={{ position: "relative" }}>
            {showActivities && (
              <Grid
                item
                xl={3}
                xs={12}
                sm={12}
                sx={{
                  position: "absolute",
                  backgroundColor: "background.default",
                  zIndex: 20,
                  left: 0,
                }}
              >
                {activitiesLoading ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 8 }}
                  >
                    <CircularProgress size={70} />
                  </Box>
                ) : (
                  <ActivitiesList
                    activities={activities}
                    selectedCourse={selectedCourse}
                    memo={memo}
                    setMemo={setMemo}
                  />
                )}
              </Grid>
            )}
            <Grid
              item
              xl={6}
              xs={12}
              sm={12}
              // p={0.1}
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: 100,
                  background:
                    "linear-gradient(180deg, rgba(237, 242, 248, 0.98) 54.68%, rgba(237, 242, 248, 0.9) 66.46%, rgba(237, 242, 248, 0) 95.32%)",
                  position: "absolute",
                  zIndex: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {moreLoading && <CircularProgress sx={{ ml: 1 }} />}
              </Box>
              <Box
                ref={messageBoxRef}
                sx={{
                  width: "100%",
                  height: "67vh",
                  overflow: "auto",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column-reverse",
                  pt: 10,
                }}
              >
                {chatHistory.map((chat, index) => {
                  if (currentMessage.length > 0 && 0 === index)
                    return (
                      <MessageComponent
                        message={currentMessage}
                        isReceived={true}
                      />
                    );

                  return (
                    <MessageComponent
                      key={index}
                      message={chat.message}
                      isReceived={chat.sender === "system"}
                    />
                  );
                })}
              </Box>
              <Box sx={{ width: "100%", p: 2, overflow: "hidden", pb: 0 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "inputs.light",
                      width: 54,
                      height: 54,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 3,
                      border: "3px solid #0959F34D",
                    }}
                  >
                    <HandIcon />
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "inputs.light",
                      width: 54,
                      height: 54,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 3,
                      border: "3px solid #0959F34D",
                    }}
                  >
                    <PaperClipIcon />
                  </Box>
                </Box>
                <TextField
                  multiline
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="message"
                  label="Message Ellie"
                  name="message"
                  autoFocus
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{}}>
                        <LoadingButton
                          disabled={/^\s*$/.test(message)}
                          loading={chatLoading}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: "transparent",
                          }}
                          color="primary"
                          variant="contained"
                          onClick={() => sendMessage(message)}
                        >
                          <ArrowUpward
                            sx={{
                              backgroundColor: chatLoading
                                ? "inputs.main"
                                : "primary",
                              color: chatLoading
                                ? "transparent"
                                : "inputs.weak",
                              borderRadius: "50%",
                            }}
                          />
                        </LoadingButton>
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "background.paper",
                      boxShadow: "0px 10px 20px 0px #9FB8D73D",
                    },
                  }}
                />
              </Box>
            </Grid>

            {showMaterials && (
              <Grid item lg={materialPreview ? 6 : 3} xs={12}>
                <EmbossedCard
                  materials={materials}
                  selectedActivity={selectedActivity}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  token: state.user.token,
  selectedCourse: state.user.selectedCourse,
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  flaggedCourses: state.user.flaggedCourses,
});

const mapDispatchToProps = {
  setSnackbar,
  setSelectedActivity,
  setFlaggedCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
