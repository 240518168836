import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, TextField } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import SchoolSelector from "components/selectors/SchoolSelector";
import { useAuth0 } from "@auth0/auth0-react";

const InviteTeacherModal = (props: any) => {
  const { schoolId, setSchoolId, open, onClose } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [email, setEmail] = useState("");
  const [expiresInDays, setExpiresInDays] = useState("7");

  const onSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      await api.invite.create(
        {
          schoolId,
          email,
          expiresInDays: Number(expiresInDays),
        },
        token,
      );

      setEmail("");
      setExpiresInDays("7");

      onClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Invite Teacher to School"
      submitText="Invite"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        <SchoolSelector schoolId={schoolId} setSchoolId={setSchoolId} />
        <TextField
          fullWidth
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Expires In (Days)"
          type="number"
          value={expiresInDays}
          onChange={(e) => setExpiresInDays(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(InviteTeacherModal);
