import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import MaterialItem from "./MaterialItem";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Renders a list of course materials.
 * @param {Object} props component props
 * @param {string} props.currentSchoolId current school id
 * @param {string} props.currentClassroomTeacherId current classroom teacher id
 * @param {string} [props.courseId] course id
 * @param {boolean} [props.noTitle=false] whether to hide the title
 * @param {boolean} [props.noButton=false] whether to hide the add button
 * @param {Function} props.setSelectedMaterial function to set the selected material
 * @param {boolean} props.materialDialogOpen whether the material dialog is open
 * @param {Function} props.toggleOpenDialog function to toggle the material dialog open
 * @param {Array} [props.materials] course materials to display
 * @param {string} [props.activityId] activity id to filter materials by
 * @returns {ReactElement} The rendered MaterialList component
 */
const MaterialList = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    currentSchoolId,
    currentClassroomTeacherId,
    courseId,
    noTitle,
    noButton,
    setSelectedMaterial,
    materialDialogOpen,
    toggleOpenDialog,
    materials: forceMaterials,
    activityId,
    addingActivity,
  } = props;

  const [materials, setMaterials] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCourse = async () => {
      const token = await getAccessTokenSilently();
      setLoading(true);
      try {
        const response = await api.course.get(
          {
            courseId,
          },
          token,
        );

        setMaterials(response.data.materials);
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        setSelectedMaterial();
        setLoading(false);
      }
    };

    if ((!activityId || !courseId) && forceMaterials)
      setMaterials(forceMaterials);
    else if (!materialDialogOpen && courseId) fetchCourse();
    // eslint-disable-next-line
  }, [courseId, materialDialogOpen]);

  return loading ? (
    <Box sx={{ display: "flex", flexDirection: "column", my: 1, gap: 2 }}>
      {Array.from({ length: 4 }, (_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          sx={{ width: "100%", height: 80 }}
        />
      ))}
    </Box>
  ) : (
    <>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.36px",
            letterSpacing: "0.02em",
            textAlign: "left",
            display: "flex",
            mt: 2,
            mb: 1,
          }}
        >
          {activityId || addingActivity ? "Activity" : "Course"} Materials
        </Typography>
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {(activityId
          ? materials.filter((el) => el.activityId == activityId)
          : materials.filter((el) => !el.activityId)
        )?.map((material, index) => (
          <MaterialItem
            key={index}
            material={material}
            courseId={courseId}
            onClick={() => {
              setSelectedMaterial(material);
              toggleOpenDialog();
            }}
            onRemove={() => {
              const updatedMaterials = [...materials];
              updatedMaterials.splice(index, 1);
              setMaterials(updatedMaterials);
            }}
          />
        ))}
      </Box>
      {!noButton && (
        <Grid item xs={12} sx={{ my: noTitle ? 1 : 0 }}>
          <Button
            onClick={toggleOpenDialog}
            color="primary"
            variant="outlined"
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              borderRadius: 1,
            }}
            startIcon={<Add />}
          >
            Add Material
          </Button>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);
