import { Box, Tooltip } from "@mui/material";
import { ObjectiveCircle } from "./ObjectiveCircles";

/**
 * A single indicator that shows comprehension level with a tooltip
 * @param {Object} props
 * @param {'No Comprehension' | 'Cursory' | 'Some' | 'Full'} props.level - The comprehension level to display
 * @param {string} props.description - Description text to show in tooltip
 * @returns {JSX.Element} A tooltipped circle indicator
 */
const ObjectiveLevelIndicator = ({ level, description }: any) => {
  // removing any trailing punctuation (period, comma, semicolon, colon)
  const cleanDescription = description.replace(/[.,;:]$/, "");

  // if level does not have "Comprehension" at end, add it
  const fullLevel = level.endsWith("Comprehension")
    ? level
    : `${level} Comprehension`;

  return (
    <Tooltip title={`${fullLevel}`}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            transform: "scale(1.1)",
            transition: "transform 0.2s",
          },
        }}
      >
        <ObjectiveCircle level={level} size={20} />
      </Box>
    </Tooltip>
  );
};

/**
 * Displays a row of objective completion indicators
 * @param {Object} props
 * @param {Object} props.analysis - Analysis data containing objectives summary
 * @param {Array<{understanding_level: string, description: string}>} props.analysis.objectives_summary - Array of objective data
 * @returns {JSX.Element|null} Row of objective indicators or null if no data
 *
 * @example
 * const analysis = {
 *   objectives_summary: [
 *     { understanding_level: 'Some', description: 'Understanding of concept X' },
 *     { understanding_level: 'Full', description: 'Mastery of skill Y' }
 *   ]
 * };
 *
 * <ObjectiveProgressIndicator analysis={analysis} />
 */
const ObjectiveProgressIndicator = ({ analysis }: any) => {
  if (!analysis?.objectives_summary) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {analysis.objectives_summary.map((objective: any, index: any) => (
        <ObjectiveLevelIndicator
          key={index}
          level={objective.understanding_level}
          description={objective.description}
        />
      ))}
    </Box>
  );
};

export default ObjectiveProgressIndicator;
