import { authInstance } from "lib/api/utils";

const planCreate = ({
  name,
  description,
  price,
  durationInMonths,
}: PlanCreateRequest) => {
  return authInstance().post<PlanResponse>(`/plans/`, {
    name,
    description,
    price,
    durationInMonths,
  });
};

const planList = () => {
  return authInstance().get<PlansResponse>(`/plans/`);
};

const planUpdate = ({
  planId,
  name,
  description,
  price,
  durationInMonths,
}: any) => {
  return authInstance().patch<PlanResponse>(`/plans/${planId}`, {
    name,
    description,
    price,
    durationInMonths,
  });
};

const planDelete = ({ planId }: { planId: string }) => {
  return authInstance().delete<GenericResponse>(`/plans/${planId}`);
};

const plan = {
  create: planCreate,
  list: planList,
  update: planUpdate,
  delete: planDelete,
};

export default plan;
