import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { TextField, Box, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutOutlined as LogoutIcon } from "@mui/icons-material";

import api from "lib/api";
import AuthLayout from "layouts/Auth";
import { setUser, clearUser, setCurrentSchoolId } from "reducers/user";
import { setSnackbar } from "reducers/ui";
import { handleUserLogout } from "utils/logout";

const StudentCreateAccountAfterAuth0 = ({
  setUser,
  setSnackbar,
  props,
  setCurrentSchoolId,
}: any) => {
  const history = useHistory();
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const [classroomCode, setClassroomCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [isAuthenticated, history]);

  const handleCreateAccount = async () => {
    if (!firstName || !lastName || !classroomCode) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields",
        severity: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();

      if (!user || !user.email || !user.sub)
        throw Error("Expected a user but got none");

      const response = await api.user.auth.register(
        {
          email: user?.email,
          firstName,
          lastName,
          joinSchool: { classroomCode },
          auth0_user_id: user?.sub,
        },
        token,
      );

      const newToken = await getAccessTokenSilently({
        cacheMode: "off",
        timeoutInSeconds: 60,
      });

      const loginResponse = await api.user.auth.login(
        {
          email: user.email,
        },
        newToken,
      );

      await setUser({
        ...loginResponse.data.user,
        organizationType: loginResponse.data.orgType,
      });

      setCurrentSchoolId(response.data.school?.id);
      setSnackbar({
        open: true,
        message: "Account created successfully!",
        severity: "success",
      });

      history.push("/classes");
    } catch (e: any) {
      console.error("Registration error:", e);
      setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <AuthLayout
      title={`Complete Your Profile`}
      quote="Complete Your Profile"
      quoteAuthor=""
    >
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            mb: 1,
            mt: 2,
            fontSize: "1rem",
          }}
        >
          Welcome {user?.email ?? "Unknown User"}!
        </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="classroomCode"
          label="Sign-up Code"
          name="classroomCode"
          autoComplete="one-time-code"
          autoFocus
          value={classroomCode}
          onChange={(e) => setClassroomCode(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: "background.paper",
            },
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="firstName"
          label="First Name"
          id="firstName"
          autoComplete="given-name"
          InputProps={{
            sx: {
              backgroundColor: "background.paper",
            },
          }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="lastName"
          label="Last Name"
          id="lastName"
          autoComplete="family-name"
          InputProps={{
            sx: {
              backgroundColor: "background.paper",
            },
          }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleCreateAccount();
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Button
            onClick={() => handleUserLogout(logout, clearUser)}
            startIcon={<LogoutIcon />}
            sx={{
              color: "secondary.dark",
              ":hover": { color: "error.main" },
            }}
          >
            Logout
          </Button>
          <LoadingButton
            loading={loading}
            sx={{
              color: "primary.contrastText",
              borderRadius: 1,
            }}
            color="primary"
            variant="contained"
            onClick={handleCreateAccount}
          >
            Complete Registration
          </LoadingButton>
        </Box>
      </Box>
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  setUser,
  setSnackbar,
  clearUser,
  setCurrentSchoolId,
};

export default connect(
  null,
  mapDispatchToProps,
)(StudentCreateAccountAfterAuth0);
