import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import migrations from "./migrations";
import * as Sentry from "@sentry/react";

import uiReducer from "reducers/ui";
import userReducer from "reducers/user";
import studentConversationReducer from "reducers/studentConversation";

const persistConfig = {
  key: "lyceum",
  version: 1,
  storage,
  blacklist: ["ui"],
  migrate: createMigrate(migrations),
};

const persistedRootReducer = persistReducer(
  persistConfig,
  combineReducers({
    ui: uiReducer,
    user: userReducer,
    studentConversation: studentConversationReducer,
  }),
);

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: persistedRootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  enhancers: [sentryReduxEnhancer],
});

const persistor = persistStore(store);

export { store, persistor };
