type Role = "admin" | "school_admin" | "teacher" | "pupil";

export const ROLE_MAPPINGS: { [r in Role]: number } = {
  admin: 1,
  school_admin: 2,
  teacher: 3,
  pupil: 4,
};

export const getAuth0Role = (user: any) => {
  if (!user) return null;

  const roleKey = Object.keys(user).find((key) => key.endsWith("/roles"));

  // return the first role or null if no roles exist
  return roleKey && user[roleKey]?.length > 0 ? user[roleKey][0] : null;
};

export const getRoleId = (auth0Role: Role) => {
  return ROLE_MAPPINGS[auth0Role] || 4;
};
