import { authInstance, createAuthHeaders } from "lib/api/utils";

const deleteCourse = ({ courseId }: { courseId: string }, token: string) => {
  return authInstance().delete<DBCourse>(
    `/courses/${courseId}`,
    createAuthHeaders(token),
  );
};
const courseCreate = (
  {
    classroomTeacherId,
    name,
    description,
    grade,
    activities,
    materials,
  }: CourseCreateRequest,
  token: string,
) => {
  return authInstance().post<CourseResponse>(
    `/courses/`,
    {
      name,
      description,
      grade,
      activities,
      materials,
      classroomTeacherId,
    },
    createAuthHeaders(token),
  );
};

const courseGet = (
  {
    courseId,
    per_page_materials = 1,
  }: { courseId: string; per_page_materials?: number },
  token: string,
) => {
  return authInstance().get<CourseResponse>(
    `/courses/${courseId}${per_page_materials ? `?per_page_materials=${per_page_materials}` : ""}`,
    createAuthHeaders(token),
  );
};

const courseUpdate = (
  {
    courseId,
    name,
    description,
    grade,
  }: { courseId: string } & CourseBasicUpdateRequest,
  token: string,
) => {
  return authInstance().patch<CourseResponse>(
    `/courses/${courseId}`,
    {
      name,
      description,
      grade,
    },
    createAuthHeaders(token),
  );
};

const courseUpdateActivitiesAndMaterials = (
  {
    courseId,
    activities,
    materials,
    removeActivities,
    removeMaterials,
    removeObjectives,
    removeQuestions,
  }: { courseId: string } & CourseUpdateActivitiesAndMaterialsRequest,
  token: string,
  per_page_materials: number,
) => {
  return authInstance().put<CourseResponse>(
    `/courses/${courseId}${per_page_materials ? `?per_page_materials=${per_page_materials}` : ""}`,
    {
      activities,
      materials,
      removeActivities,
      removeMaterials,
      removeObjectives,
      removeQuestions,
    },
    createAuthHeaders(token),
  );
};

const course = {
  create: courseCreate,
  get: courseGet,
  update: courseUpdate,
  updateActivitiesAndMaterials: courseUpdateActivitiesAndMaterials,
  delete: deleteCourse,
};

export default course;
