import React, { useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import StudentSelector from "components/selectors/StudentSelector";
import { isStrongPassword, validateEmail } from "lib/helpers";
import { useAuth0 } from "@auth0/auth0-react";

const CreateUserModal = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    rolesId,
    inviteToken,
    code,
    students,
    addToClassroom,
    open,
    onClose,
  } = props;

  const [studentId, setStudentId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [firstAttemptMade, setFirstAttemptMade] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const handleEmailChange = (e: any) => {
    let text = e.target.value.replace(/\s/g, "");
    setEmail(text);

    if (firstAttemptMade)
      setValidationErrors({ ...validationErrors, email: validateEmail(text) });
  };

  const handlePasswordChange = (e: any) => {
    let text = e.target.value.replace(/\s/g, "");
    setPassword(text);

    if (firstAttemptMade)
      setValidationErrors({
        ...validationErrors,
        password: isStrongPassword(text),
      });
  };

  const validateForm = () => {
    setFirstAttemptMade(true);
    const isEmailValid = validateEmail(email);
    const isPasswordStrong = isStrongPassword(password);

    setValidationErrors({
      email: isEmailValid,
      password: isPasswordStrong,
    });

    return isEmailValid === true && isPasswordStrong === true;
  };

  const onSubmit = async () => {
    const token = await getAccessTokenSilently();
    const valid = validateForm();
    if (valid === false && studentId === "") return;

    try {
      let newUserId: number = 0;
      if (!studentId) {
        const data: any = {
          email,
          password,
          firstName,
          lastName,
          rolesId,
          joinSchool: {},
        };
        if (inviteToken) data.joinSchool.inviteToken = inviteToken;
        if (code) data.joinSchool.code = code;
        if (addToClassroom) data.joinSchool.code = props.currentSchoolCode;

        const response = await api.user.auth.register({ ...data }, token);
        newUserId = response.data.user.id;
      }

      if (addToClassroom) {
        await api.classroom.updatePupils(
          {
            classroomId: props.currentClassroomId as string,
            pupils: [
              studentId ? Number(studentId) : Number(newUserId),
            ] as number[],
          },
          token,
        );
      }

      handleClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const handleClose = () => {
    setStudentId("");
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title={`Add ${rolesId === 3 ? "Teacher" : "Student"}`}
      submitText="Add"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        {addToClassroom && (
          <StudentSelector
            schoolId={props.currentSchoolId}
            filterOut={students.map((student: any) => student.id)}
            studentId={studentId}
            setStudentId={setStudentId}
          />
        )}
        {/* {studentId === '' && (
          <>
            {rolesId === 4 && (
              <Typography textAlign="center">- OR -</Typography>
            )}
            <TextField
              fullWidth
              label="Email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              error={Boolean(
                firstAttemptMade && validationErrors.email !== true
              )}
              helperText={validationErrors.email}
              FormHelperTextProps={{
                sx: { whiteSpace: 'pre-line', color: 'error.main' },
              }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
              error={Boolean(
                firstAttemptMade && validationErrors.password !== true
              )}
              helperText={validationErrors.password}
              FormHelperTextProps={{
                sx: { whiteSpace: 'pre-line', color: 'error.main' },
              }}
            />
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              type="text"
              autoComplete="given-name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Last Name"
              type="text"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </>
        )} */}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
