import { Box } from "@mui/material";

/**
 * A circular indicator component that visualizes different levels of comprehension
 * @param {Object} props - The component props
 * @param {'No Comprehension' | 'Cursory' | 'Some' | 'Full'} props.level - The comprehension level to display
 * @param {number} [props.size=20] - The size of the circle in pixels
 * @returns {JSX.Element} A circular indicator with styling based on the comprehension level
 *
 * @example
 * // Basic usage
 * <ObjectiveCircle level="Some" />
 *
 * // Custom size
 * <ObjectiveCircle level="Full" size={32} />
 */
export const ObjectiveCircle = ({ level, size = 20 }: any) => {
  switch (level) {
    case "No Comprehension":
      return (
        <Box
          sx={{
            width: size,
            height: size,
            borderRadius: "50%",
            bgcolor: "#E3F2FD",
            border: "2px solid #E3F2FD",
          }}
        />
      );

    case "Cursory":
      return (
        <Box
          sx={{
            width: size,
            height: size,
            borderRadius: "50%",
            bgcolor: "#E3F2FD",
            border: "2px solid #1976D2",
          }}
        />
      );

    case "Some":
      return (
        <Box
          sx={{
            width: size,
            height: size,
            borderRadius: "50%",
            border: "2px solid #1976D2",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "50%",
              height: "100%",
              left: 0,
              bgcolor: "#E3F2FD",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "50%",
              height: "100%",
              right: 0,
              bgcolor: "#1976D2",
            }}
          />
        </Box>
      );

    case "Full":
      return (
        <Box
          sx={{
            width: size,
            height: size,
            borderRadius: "50%",
            bgcolor: "#1976D2",
            border: "2px solid #1976D2",
          }}
        />
      );

    default:
      return (
        <Box
          sx={{
            width: size,
            height: size,
            borderRadius: "50%",
            border: "2px solid #E0E0E0",
          }}
        />
      );
  }
};
