import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, TextField } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";

const CreateAdminModal = (props: any) => {
  const { open, onClose } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const onSubmit = async () => {
    try {
      await api.admin.auth.create({
        email,
        password,
        firstName,
        lastName,
      });

      handleClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const handleClose = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Create Admin"
      submitText="Create"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        <TextField
          fullWidth
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          fullWidth
          label="First Name"
          value={firstName}
          type="text"
          autoComplete="given-name"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          type="text"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(CreateAdminModal);
