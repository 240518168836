import { authInstance, createAuthHeaders, instance } from "lib/api/utils";
const userAuthLogin = ({ email }: { email: string }, token: string) => {
  return instance.post<BaseUserLoginResponse>(
    `/users/auth/login`,
    {
      email,
    },
    createAuthHeaders(token),
  );
};

const userAuthRegister = (
  {
    email,
    firstName,
    lastName,
    school,
    joinSchool,
    auth0_user_id,
  }: UserRegisterRequest,
  token: string,
) => {
  return instance.post<BaseUserRegisterResponse>(
    `/users/auth/register`,
    {
      email,
      firstName,
      lastName,
      school,
      joinSchool,
      auth0_user_id,
    },
    createAuthHeaders(token),
  );
};

const userList = (
  {
    schoolId,
    roleId,
    page = 1,
    rowsPerPage = 10,
  }: { schoolId: string; roleId: string; page?: number; rowsPerPage?: number },
  token: string,
) => {
  let url = `/users/?page=${page}&per_page=${rowsPerPage}`;

  if (schoolId && roleId) {
    url += `&schools_id=${schoolId}&roles_id=${roleId}`;
  } else if (schoolId) {
    url += `&schools_id=${schoolId}`;
  } else if (roleId) {
    url += `&roles_id=${roleId}`;
  }

  return authInstance().get<UsersWithSchoolRoleResponse>(
    url,
    createAuthHeaders(token),
  );
};

const userUpdate = (
  {
    email,
    firstName,
    lastName,
    profileImageLink,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    profileImageLink: string;
  },
  token: string,
) => {
  return instance.patch<BaseUserID>(
    `/users/me`,
    {
      email,
      firstName,
      lastName,
      profileImageLink,
    },
    createAuthHeaders(token),
  );
};

const userMasterUpdate = (
  {
    userId,
    email,
    firstName,
    lastName,
    profileImageLink,
  }: {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    profileImageLink: string;
  },
  token: string,
) => {
  return instance.patch<BaseUserID>(
    `/users/${userId}`,
    {
      email,
      firstName,
      lastName,
      profileImageLink,
    },
    createAuthHeaders(token),
  );
};

const user = {
  auth: {
    login: userAuthLogin,
    register: userAuthRegister,
  },
  list: userList,
  update: userUpdate,
  masterUpdate: userMasterUpdate,
};

export default user;
