import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, TextField } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";

const EditAdminModal = (props: any) => {
  const { selectedAdmin, open, onClose } = props;

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const onSubmit = async () => {
    try {
      if (selectedAdmin.master === true) {
        await api.admin.update({
          email,
          newPassword,
          oldPassword,
          firstName,
          lastName,
        });
      } else
        await api.admin.masterUpdate({
          adminId: selectedAdmin.id,
          email,
          newPassword,
          oldPassword,
          firstName,
          lastName,
        });

      setEmail("");
      setNewPassword("");
      setOldPassword("");
      setFirstName("");
      setLastName("");

      onClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  useEffect(() => {
    setEmail(selectedAdmin?.email || "");
    setNewPassword("");
    setOldPassword("");
    setFirstName(selectedAdmin?.firstName || "");
    setLastName(selectedAdmin?.lastName || "");
  }, [selectedAdmin]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Edit Admin"
      submitText="Save"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        <TextField
          fullWidth
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          autoComplete="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          fullWidth
          label="Old Password"
          type="password"
          autoComplete="current-password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <TextField
          fullWidth
          label="First Name"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(EditAdminModal);
