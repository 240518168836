import React from "react";
import { Box, Typography, styled, Grid, Paper } from "@mui/material";
import AppLogo from "components/AppLogo";

const ImageContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#1E88E5",
  color: "#fff",
  display: "flex",
  flexDirection: "column",

  padding: theme.spacing(6),
}));

const ImageText = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  width: "84%",
}));

const FormContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),
  backgroundColor: "rgba('#fff', 0)",
}));

const Auth = (props: any) => (
  <Box
    sx={{
      backgroundColor: "background.default",
      height: "100vh",
    }}
  >
    <Grid
      container
      component={Paper}
      p={0}
      m={0}
      sx={{
        background: "linear-gradient(135deg,#EFF3F8, #E8EFF7)",
      }}
    >
      <ImageContainer
        item
        sx={{
          background: "linear-gradient(135deg,#0959F3, #0345C4)",
          height: "100vh",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        xs={12}
        sm={12}
        md={6}
      >
        <AppLogo />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            height: "60%",
          }}
        >
          <ImageText
            variant="h6"
            fontSize={28}
            sx={{
              pb: 2,
            }}
          >
            {props.quote}
          </ImageText>
          <Typography fontSize={16}>{props.quoteAuthor}</Typography>
        </Box>
      </ImageContainer>
      <FormContainer
        item
        sx={{
          height: "100vh",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          component="h1"
          variant="h5"
          fontWeight="medium"
          fontSize={24}
          color="secondary.main"
        >
          {props.title}
        </Typography>
        {props.children}
      </FormContainer>
    </Grid>
  </Box>
);

export default Auth;
