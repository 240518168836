import { authInstance, createAuthHeaders } from "lib/api/utils";
const materialUpdate = ({ material }: MaterialUpdateRequest, token: string) => {
  return authInstance().patch<MaterialResponse>(
    "/materials/",
    { material },
    createAuthHeaders(token),
  );
};

const materialCreate = (
  { material }: { material: MaterialCreateRequest },
  token: string,
) => {
  return authInstance().post<MaterialResponse>(
    "/materials/",
    material,
    createAuthHeaders(token),
  );
};

const materialDelete = (
  { materialId }: { materialId: string },
  token: string,
) => {
  return authInstance().delete<MaterialResponse>(
    `/materials/${materialId}`,
    createAuthHeaders(token),
  );
};

const material = {
  update: materialUpdate,
  create: materialCreate,
  delete: materialDelete,
};

export default material;
