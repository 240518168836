import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import { useAuth0 } from "@auth0/auth0-react";

const StudentSelector = (props: any) => {
  const { schoolId, wholeUser, filterOut, studentId, setStudentId } = props;
  const [students, setStudents] = useState<Array<any>>([]);
  const { getAccessTokenSilently } = useAuth0();

  const handleChange = (event: any) => {
    setStudentId(event.target.value);
  };

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.user.list(
          {
            roleId: "4",
            schoolId,
            rowsPerPage: 999,
          },
          token,
        );
        if (filterOut) {
          setStudents(
            response.data.users.filter(
              (student: any) => !filterOut.includes(student.user.id),
            ),
          );
        } else setStudents(response.data.users);
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    };

    fetchStudents();

    return () => {
      setStudents([]);
    };

    // eslint-disable-next-line
  }, [schoolId]);

  return (
    <FormControl fullWidth>
      <InputLabel id="studentId-selector-label">Student</InputLabel>
      <Select
        labelId="studentId-selector-label"
        id="studentId-selector"
        value={studentId}
        label="Student"
        onChange={handleChange}
      >
        {students &&
          students.map((student) => (
            <MenuItem
              key={student.user.id}
              value={wholeUser ? student.user : student.user.id}
            >
              {student.user.firstName} {student.user.lastName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(StudentSelector);
