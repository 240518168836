import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, TextField } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import { useAuth0 } from "@auth0/auth0-react";

const EditCourseModal = (props: any) => {
  const { schoolId, classroomTeacherId, selectedCourse, open, onClose } = props;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [grade, setGrade] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setName(selectedCourse?.name || "");
    setDescription(selectedCourse?.description || "");
    setGrade(selectedCourse?.grade || "");
  }, [selectedCourse]);

  const onSubmit = async () => {
    try {
      await api.course.update(
        {
          courseId: selectedCourse.id,
          name,
          description,
          grade,
        },
        await getAccessTokenSilently(),
      );

      setName("");
      setDescription("");
      setGrade("");

      onClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Edit Course"
      submitText="Save"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        <TextField
          fullWidth
          label="Name"
          type="text"
          autoComplete="organization"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          multiline
          label="Description"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          fullWidth
          label="Grade"
          type="number"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(EditCourseModal);
