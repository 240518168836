import { authInstance, createAuthHeaders } from "lib/api/utils";

const classroomCreate = (
  {
    schoolId,
    name,
    startDate,
    endDate,
    maxPupils,
    pupils,
    teachers,
  }: ClassroomCreateRequest,
  token: string,
) => {
  return authInstance().post<ClassroomResponse>(
    `/classrooms/`,
    {
      schoolId,
      name,
      startDate,
      endDate,
      maxPupils,
      pupils,
      teachers,
    },
    createAuthHeaders(token),
  );
};

const classroomGet = (
  { classroomId }: { classroomId: string },
  token: string,
) => {
  return authInstance().get<ClassroomResponse>(
    `/classrooms/${classroomId}`,
    createAuthHeaders(token),
  );
};

const classroomGetCode = (
  { classroomId }: { classroomId: string },
  token: string,
) => {
  return authInstance().get<string>(
    `/classrooms/${classroomId}/code`,
    createAuthHeaders(token),
  );
};

const enrollStudentInClassroom = (
  { classroomCode, userId }: { classroomCode: string; userId: string },
  token: string,
) => {
  return authInstance().post<{
    message: "Enrollment successful";
    classroomId: number;
  }>(
    `/classrooms/${classroomCode}/enroll/${userId}`,
    {},
    createAuthHeaders(token),
  );
};

const courseList = (
  {
    classroomTeacherId,
    page = 1,
    rowsPerPage = 10,
  }: { classroomTeacherId: string; page?: number; rowsPerPage?: number },
  token: string,
) => {
  return authInstance().get<CoursesResponse>(
    `/classrooms/${classroomTeacherId}/courses/?page=${page}&per_page=${rowsPerPage}`,
    createAuthHeaders(token),
  );
};

const classroomUpdate = (
  {
    classroomId,
    name,
    startDate,
    endDate,
    maxPupils,
  }: { classroomId: string } & ClassroomBasicUpdateRequest,
  token: string,
) => {
  return authInstance().patch<ClassroomResponse>(
    `/classrooms/${classroomId}`,
    {
      name,
      startDate,
      endDate,
      maxPupils,
    },
    createAuthHeaders(token),
  );
};

const classroomUpdatePupils = (
  {
    classroomId,
    pupils,
    teachers,
    removePupils,
    removeTeachers,
  }: { classroomId: string } & ClassroomMembersUpdateRequest,
  token: string,
) => {
  return authInstance().put<ClassroomResponse>(
    `/classrooms/${classroomId}`,
    {
      pupils,
      teachers,
      removePupils,
      removeTeachers,
    },
    createAuthHeaders(token),
  );
};

const classroom = {
  create: classroomCreate,
  get: classroomGet,
  update: classroomUpdate,
  updatePupils: classroomUpdatePupils,
  getCode: classroomGetCode,
  enroll: enrollStudentInClassroom,
  listCourses: courseList,
};

export default classroom;
