import { Box, Typography } from "@mui/material";
import { ObjectiveCircle } from "./ObjectiveCircles";
import { ReactNode } from "react";

/**
 * Single legend item displaying a comprehension level indicator and its label
 * @param {Object} props
 * @param {'No Comprehension' | 'Cursory' | 'Some' | 'Full'} props.level - The comprehension level to display
 * @returns {JSX.Element} A legend item with circle indicator and label
 */
const LegendItem = ({ level }: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <ObjectiveCircle level={level} size={16} />
      <Typography sx={{ fontSize: 12, color: "text.secondary" }}>
        {level}
      </Typography>
    </Box>
  );
};

/**
 * Legend component that displays all possible comprehension levels
 * Shows a horizontal list of indicators with their corresponding labels
 * @returns {JSX.Element} A complete legend for objective comprehension levels
 *
 * @example
 * // Basic usage
 * <ObjectiveLegend />
 *
 * // Inside a parent component
 * <Box>
 *   <ObjectiveLegend />
 *   <ObjectiveProgressIndicator analysis={analysis} />
 * </Box>
 */
const ObjectiveLegend = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      p: 0.5,
    }}
  >
    <Box sx={{ display: "flex", gap: "1rem" }}>
      {["No Comprehension", "Cursory", "Some", "Full"].map((level: any) => (
        <LegendItem key={level} level={level} />
      ))}
    </Box>
  </Box>
);

export default ObjectiveLegend;
