import React from "react";

import AuthLayout from "layouts/Auth";
// @ts-expect-error TS(2307): Cannot find module 'assets/qr-code-static.png' or ... Remove this comment to see the full error message
import QrCodeImage from "assets/qr-code-static.png";

const imageSize = "240rem";

const QR = () => {
  return (
    <AuthLayout
      title=""
      quote={`Welcome to the future of education.`}
      quoteAuthor=""
    >
      <img src={QrCodeImage} alt="QR" width={imageSize} height={imageSize} />
    </AuthLayout>
  );
};

export default QR;
