import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Skeleton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { setSnackbar } from "reducers/ui";
import {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setCurrentSchoolId,
  setSelectedActivity,
  setSelectedCourse,
} from "reducers/user";
import CourseItem from "components/CourseItem";
import { useCourses } from "hooks/useCourses";
import api from "lib/api";
import { useAuth0 } from "@auth0/auth0-react";

const CourseList = (props: any) => {
  const { userId, role, currentSchoolId, flaggedCourses } = props;

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [classroomCode, setClassroomCode] = useState("");
  const [enrolling, setEnrolling] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const courses = useCourses({
    role,
    userId,
    currentSchoolId,
    setCurrentSchoolId: props.setCurrentSchoolId,
    setCurrentClassroomTeacherId: props.setCurrentClassroomTeacherId,
    setCurrentClassroomId: props.setCurrentClassroomId,
    setSelectedCourse: props.setSelectedCourse,
    setSelectedActivity: props.setSelectedActivity,
    setSnackbar: props.setSnackbar,
    setLoading,
  });

  const handleEnrollClass = async () => {
    setEnrolling(true);
    try {
      const token = await getAccessTokenSilently();

      await api.classroom.enroll(
        {
          classroomCode: classroomCode,
          userId,
        },
        token,
      );
      props.setSnackbar({
        open: true,
        message: "Successfully enrolled in the class!",
        severity: "success",
      });
      setOpenDialog(false);
      setLoading(true);

      window.location.reload(); // reload the page (there's a better way to do this, should be updated)

      setLoading(false);
    } catch (error: any) {
      console.error("Enrollment error:", error);
      props.setSnackbar({
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Failed to enroll in the class",
        severity: "error",
      });
    } finally {
      setEnrolling(false);
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          width: "100%",
          height: "90vh",
          maxHeight: "90vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 400,
            p: 2,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontFamily="Inter"
            textAlign="start"
          >
            My Classes
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              width: "fit-content",
              borderRadius: 1,
            }}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={15}>
              Join Class
            </Typography>
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Array.from({ length: 6 }, (_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                animation={index % 2 === 0 ? "wave" : "pulse"}
                sx={{ width: 360, height: 50, borderRadius: 2 }}
              />
            ))}
            <CircularProgress
              sx={{ position: "absolute", opacity: 0.5 }}
              color="info"
              size={120}
            />
          </Box>
        ) : (
          <Box
            sx={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
              maxHeight: "70vh",
              overflowY: "auto",
              maxWidth: 400,
              px: 2,
              pb: 3,
            }}
          >
            {courses?.map((item, index) => (
              <CourseItem
                key={index}
                noEdit
                course={item}
                flagged={flaggedCourses?.includes(item.id)}
              />
            ))}
          </Box>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            borderRadius: 8,
            padding: 16,
          },
        }}
      >
        <DialogContent>
          {enrolling ? (
            <Box
              sx={{
                display: "flex",
                height: 250,
                width: 400,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={200} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: 400,
              }}
            >
              <Typography variant="h6" fontFamily="Inter" fontWeight="bold">
                Join a Class
              </Typography>
              <Typography
                fontFamily="Inter"
                fontSize={12}
                color="textSecondary"
              >
                Enter the classroom code given to you by your teacher.
              </Typography>
              <TextField
                autoFocus
                fullWidth
                id="classroomCode"
                label="Classroom Code"
                type="text"
                variant="outlined"
                value={classroomCode}
                onChange={(e) => setClassroomCode(e.target.value)}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            sx={{
              fontFamily: "Inter",
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEnrollClass}
            disabled={enrolling || !classroomCode}
            variant="contained"
            sx={{
              fontFamily: "Inter",
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            {enrolling ? "Joining..." : "Join"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  role: state.user.role,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  flaggedCourses: state.user.flaggedCourses,
});

const mapDispatchToProps = {
  setSelectedCourse,
  setSelectedActivity,
  setCurrentSchoolId,
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
