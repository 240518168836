import { authInstance, createAuthHeaders } from "lib/api/utils";
const createCourseActivity = (
  activity: ActivityCreateRequest,
  token: string,
) => {
  return authInstance().post<DBCourseActivity>(
    "/activities/",
    activity,
    createAuthHeaders(token),
  );
};

const activityGetAnalytics = (
  { activityId, pupilId }: { activityId: string; pupilId: string },
  token: string,
) => {
  return authInstance().get<LatestObjectivesSummaryResponse>(
    `/activities/${activityId}/analytics/${pupilId}`,
    createAuthHeaders(token),
  );
};

const activityGetSentiments = (
  { activityId, pupilId }: { activityId: string; pupilId: string },
  token: string,
) => {
  return authInstance().get<CourseActivityObjectiveSentimentAnalyticsResponse>(
    `/activities/${activityId}/sentiment_analytics/${pupilId}`,
    createAuthHeaders(token),
  );
};

const activities = {
  createActivity: createCourseActivity,
  getAnalytics: activityGetAnalytics,
  getSentiments: activityGetSentiments,
};

export default activities;
